import * as i0 from '@angular/core';
import { Directive, SkipSelf, Input, Injectable, HostListener, Pipe, EventEmitter, Output, HostBinding, Optional, NgModule, InjectionToken, APP_INITIALIZER } from '@angular/core';
import { fromEvent, of, concatMapTo, timer, repeat, Subject, takeUntil, tap, firstValueFrom, lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import * as i2 from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { EventArgs, isNumber as isNumber$1 } from '@awesome-nodes/object';
import * as i1 from '@yukawa/chain-base-angular-client';
import { ObjectModel, TemplateString, EventDelegate, AppInjector, isNumber, ArgumentException, Exception } from '@yukawa/chain-base-angular-client';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import moment from 'moment';
import * as i1$1 from '@ngneat/transloco';
import { TranslocoService, TRANSLOCO_CONFIG, translocoConfig, TranslocoModule, TRANSLOCO_LOADER } from '@ngneat/transloco';
import { ComponentPortal } from '@angular/cdk/portal';
import * as i1$5 from '@ctrl/ngx-emoji-mart';
import { PickerComponent, PickerModule } from '@ctrl/ngx-emoji-mart';
import * as i1$2 from '@angular/cdk/overlay';
import * as i1$3 from '@angular/material/tabs';
import * as i1$4 from '@angular/forms';
import emojiRegex from 'emoji-regex';
import { DomSanitizer, HammerGestureConfig } from '@angular/platform-browser';
import { marked } from 'marked';
import { MatPaginatorIntl } from '@angular/material/paginator';
import * as i1$6 from '@angular/common/http';
class AutocompleteOptionsDirective {
  _changeDetector;
  _elementRef;
  search;
  #keyupSubscription;
  constructor(_changeDetector, _elementRef) {
    this._changeDetector = _changeDetector;
    this._elementRef = _elementRef;
  }
  ngOnChanges(changes) {
    if (changes['search'].currentValue) {
      this.#keyupSubscription = fromEvent(this._elementRef.nativeElement, 'keyup').pipe(debounceTime(1000), distinctUntilChanged()).subscribe(async () => {
        let value = this._elementRef.nativeElement.value;
        // eslint-disable-next-line eqeqeq
        if (value == '') {
          value = null;
        }
        await this.search(value);
        this._changeDetector.detectChanges();
      });
    }
  }
  ngOnDestroy() {
    this.#keyupSubscription?.unsubscribe();
  }
  /** @nocollapse */
  static ɵfac = function AutocompleteOptionsDirective_Factory(t) {
    return new (t || AutocompleteOptionsDirective)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef, 4), i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: AutocompleteOptionsDirective,
    selectors: [["input", "autocompleteOptions", ""]],
    inputs: {
      search: "search"
    },
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutocompleteOptionsDirective, [{
    type: Directive,
    args: [{
      selector: 'input[autocompleteOptions]'
    }]
  }], () => [{
    type: i0.ChangeDetectorRef,
    decorators: [{
      type: SkipSelf
    }]
  }, {
    type: i0.ElementRef
  }], {
    search: [{
      type: Input
    }]
  });
})();
class NavigateBackEventArgs extends EventArgs {
  #events = new Array();
  #cancel = false;
  constructor() {
    super();
  }
  get cancel() {
    return this.#cancel;
  }
  set cancel(value) {
    this.#cancel = value;
  }
  get events() {
    return this.#events;
  }
}
class ToggleFooterEventArgs extends EventArgs {
  #display = false;
  constructor(display) {
    super();
    this.#display = display;
  }
  get display() {
    return this.#display;
  }
  set display(value) {
    this.#display = value;
  }
}
class NavigationService extends ObjectModel {
  _configService;
  _router;
  _location;
  _ngZone;
  navigateBack;
  toggleFooter;
  _previousUrl;
  _backToPreviousUrl;
  _storeKey;
  #history = [];
  constructor(_configService, _router, _location, _ngZone) {
    super();
    this._configService = _configService;
    this._router = _router;
    this._location = _location;
    this._ngZone = _ngZone;
    this._storeKey = TemplateString`${'store'}=${'item'}`({
      store: this._configService.getValue('sessionStoreKey'),
      item: this._configService.getValue('sessionStorePrefix') + 'navigationHistory'
    });
    this.loadNavigationHistory();
    this.monitorRouterEvents();
    this.navigateBack = new EventDelegate(this);
    this.toggleFooter = new EventDelegate(this);
  }
  /**
   * Returns the previous url in the history which path is different from the current url.
   *
   * @return `string | undefined` The previous url or undefined if there is no previous url.
   */
  get previousUrl() {
    return this._previousUrl;
  }
  goBackToPreviousUrl() {
    this._backToPreviousUrl = this._previousUrl;
  }
  async back(extras) {
    const lastUrl = this.#history.pop();
    const ea = new NavigateBackEventArgs();
    this.navigateBack.invoke(ea);
    if (ea.events.length > 0) {
      await Promise.all(ea.events);
    }
    if (ea.cancel) {
      if (lastUrl) {
        this.#history.push(lastUrl);
      }
      return false;
    }
    let url = this.#history.pop();
    if (this._backToPreviousUrl) {
      while (url && url !== this._backToPreviousUrl) {
        url = this.#history.pop();
      }
      this._backToPreviousUrl = null;
    }
    return this._ngZone.run(async () => url ? await this._router.navigateByUrl(url, extras) : await this._router.navigate(['..'], extras));
  }
  clearHistory() {
    this.#history = [];
    localStorage.removeItem(this._storeKey);
  }
  loadNavigationHistory() {
    const savedUrls = localStorage.getItem(this._storeKey);
    if (savedUrls) {
      this.#history = JSON.parse(savedUrls);
      this._previousUrl = this.#history[this.#history.length - 1] || null;
    }
  }
  saveNavigationHistory() {
    localStorage.setItem(this._storeKey, JSON.stringify(this.#history));
    //console.log(JSON.stringify(this.#history, null, 2));
  }
  monitorRouterEvents() {
    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      const currentUrl = event.urlAfterRedirects;
      if (this.#history.length > 0) {
        const lastUrl = this.#history[this.#history.length - 1];
        const secondToLastUrl = this.#history.length > 1 ? this.#history[this.#history.length - 2] : null;
        const lastUrlBase = lastUrl.split('?')[0];
        const currentUrlBase = currentUrl.split('?')[0];
        if (currentUrl === lastUrl) {
          // Current URL is the same as the last URL, no changes needed
        } else if (currentUrl === secondToLastUrl) {
          // If navigating back to the previous URL, remove the last URL
          this.#history.pop();
        } else if (lastUrlBase === currentUrlBase) {
          this.#history[this.#history.length - 1] = currentUrl; // Replace if same path but different query params
        } else {
          // Remove any previous URLs with the same base path as the current URL
          this.#history = this.#history.filter(url => !url.split('?')[0].startsWith(currentUrlBase));
          // Remove the last details URL if it is the same as the current URL
          if (currentUrl.includes('details') && lastUrlBase.includes('details')) {
            this.#history.pop();
          }
          // Add the current URL
          this.#history.push(currentUrl);
        }
        this._previousUrl = this.#history.length > 1 ? this.#history[this.#history.length - 2] : null;
      } else {
        this.#history.push(currentUrl);
        this._previousUrl = null;
      }
      this.saveNavigationHistory();
    });
  }
  /** @nocollapse */
  static ɵfac = function NavigationService_Factory(t) {
    return new (t || NavigationService)(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NavigationService,
    factory: NavigationService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavigationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ConfigService
  }, {
    type: i2.Router
  }, {
    type: i3.Location
  }, {
    type: i0.NgZone
  }], null);
})();
class BackButtonDirective {
  _navigation;
  constructor(_navigation) {
    this._navigation = _navigation;
  }
  onClick() {
    this._navigation.back();
  }
  /** @nocollapse */
  static ɵfac = function BackButtonDirective_Factory(t) {
    return new (t || BackButtonDirective)(i0.ɵɵdirectiveInject(NavigationService));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: BackButtonDirective,
    selectors: [["", "backButton", ""]],
    hostBindings: function BackButtonDirective_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("click", function BackButtonDirective_click_HostBindingHandler() {
          return ctx.onClick();
        });
      }
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BackButtonDirective, [{
    type: Directive,
    args: [{
      selector: '[backButton]'
    }]
  }], () => [{
    type: NavigationService
  }], {
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class DefaultImageDirective {
  src;
  defaultImg = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  onError() {
    this.src = this.defaultImg;
  }
  checkPath(src) {
    return src ? src : this.defaultImg;
  }
  /** @nocollapse */
  static ɵfac = function DefaultImageDirective_Factory(t) {
    return new (t || DefaultImageDirective)();
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: DefaultImageDirective,
    selectors: [["img", "src", ""]],
    hostVars: 1,
    hostBindings: function DefaultImageDirective_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("error", function DefaultImageDirective_error_HostBindingHandler() {
          return ctx.onError();
        });
      }
      if (rf & 2) {
        i0.ɵɵhostProperty("src", ctx.checkPath(ctx.src), i0.ɵɵsanitizeUrl);
      }
    },
    inputs: {
      src: "src",
      defaultImg: "defaultImg"
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultImageDirective, [{
    type: Directive,
    args: [{
      selector: 'img[src]',
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        '[src]': 'checkPath(src)',
        '(error)': 'onError()'
      }
    }]
  }], null, {
    src: [{
      type: Input
    }],
    defaultImg: [{
      type: Input
    }]
  });
})();
class ElapsedDatePipe {
  _translocoService;
  static _localeSpecifications = new Map();
  withoutSuffix = false;
  dateFormat = 'long';
  constructor(_translocoService) {
    this._translocoService = _translocoService;
  }
  get localeResourceKey() {
    let key = 'FORMAT.ELAPSED_DATE';
    if (this.dateFormat === 'short') {
      key += '_SHORT';
    }
    return key;
  }
  transform(value, ...args) {
    if (value == null) {
      return;
    }
    const activeLang = this._translocoService.getActiveLang();
    const langKey = `${activeLang}-${this.dateFormat}`;
    if (!ElapsedDatePipe._localeSpecifications.has(langKey)) {
      this.initLocale(langKey);
    }
    moment.updateLocale(activeLang, ElapsedDatePipe._localeSpecifications.get(langKey));
    let secondsElapsed = moment().diff(value, 'seconds');
    if (secondsElapsed < 0) {
      secondsElapsed *= -1;
    }
    const dayStart = moment(value).startOf('day').seconds(secondsElapsed);
    if (secondsElapsed > 300) {
      return moment(value).fromNow(this.withoutSuffix);
    } else if (secondsElapsed < 60) {
      return this._translocoService.translate(this.localeResourceKey + '.SECOND').replace('%d', dayStart.format('s'));
    } else {
      return this._translocoService.translate(this.localeResourceKey + '.MINUTE').replace('%d', dayStart.format('m'));
    }
  }
  initLocale(lang) {
    ElapsedDatePipe._localeSpecifications.set(lang, {
      relativeTime: {
        /* eslint-disable @typescript-eslint/naming-convention */
        future: this._translocoService.translate(this.localeResourceKey + '.FUTURE'),
        past: this._translocoService.translate(this.localeResourceKey + '.PAST'),
        s: this._translocoService.translate(this.localeResourceKey + '.SECOND'),
        ss: this._translocoService.translate(this.localeResourceKey + '.SECONDS'),
        m: this._translocoService.translate(this.localeResourceKey + '.MINUTE'),
        mm: this._translocoService.translate(this.localeResourceKey + '.MINUTES'),
        h: this._translocoService.translate(this.localeResourceKey + '.HOUR'),
        hh: this._translocoService.translate(this.localeResourceKey + '.HOURS'),
        d: this._translocoService.translate(this.localeResourceKey + '.DAY'),
        dd: days => {
          // round to the closest number of weeks
          const weeks = Math.round(days / 7);
          if (days < 7) {
            // if less than a week, use days
            return this._translocoService.translate(days === 1 ? this.localeResourceKey + '.DAY' : this.localeResourceKey + '.DAYS').replace('%d', String(days));
          } else {
            // pluralize weeks
            return this._translocoService.translate(weeks === 1 ? this.localeResourceKey + '.WEEK' : this.localeResourceKey + '.WEEKS').replace('%d', String(weeks));
          }
        },
        w: this._translocoService.translate(this.localeResourceKey + '.WEEK'),
        ww: this._translocoService.translate(this.localeResourceKey + '.WEEKS'),
        M: this._translocoService.translate(this.localeResourceKey + '.MONTH'),
        MM: this._translocoService.translate(this.localeResourceKey + '.MONTHS'),
        y: this._translocoService.translate(this.localeResourceKey + '.YEAR'),
        yy: this._translocoService.translate(this.localeResourceKey + '.YEARS')
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    });
  }
  /** @nocollapse */
  static ɵfac = function ElapsedDatePipe_Factory(t) {
    return new (t || ElapsedDatePipe)(i0.ɵɵdirectiveInject(i1$1.TranslocoService, 16));
  };
  /** @nocollapse */
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "elapsedDate",
    type: ElapsedDatePipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ElapsedDatePipe, [{
    type: Pipe,
    args: [{
      name: 'elapsedDate'
    }]
  }], () => [{
    type: i1$1.TranslocoService
  }], {
    withoutSuffix: [{
      type: Input
    }],
    dateFormat: [{
      type: Input
    }]
  });
})();
class ElapsedDateDirective {
  _elementRef;
  value;
  update = true;
  updateInterval = 5;
  dateFormat = 'long';
  withoutSuffix = false;
  #intervalSubscription;
  _elapsedPipe;
  constructor(_elementRef, _translocoService) {
    this._elementRef = _elementRef;
    this._elapsedPipe = new ElapsedDatePipe(_translocoService);
  }
  ngOnInit() {
    this._elapsedPipe.dateFormat = this.dateFormat;
    this._elapsedPipe.withoutSuffix = this.withoutSuffix;
    const innerText = this._elapsedPipe.transform(this.value) || String(this.value);
    if (this._elementRef.nativeElement.innerText !== innerText) {
      this._elementRef.nativeElement.innerText = innerText;
    }
  }
  ngAfterViewInit() {
    if (this.update) {
      this.#intervalSubscription = of(null).pipe(concatMapTo(timer(1000)), repeat()).subscribe(() => {
        const diffSeconds = moment().diff(this.value, 'seconds');
        if (diffSeconds % this.updateInterval === 0) {
          this.ngOnInit();
        }
      });
    }
  }
  ngOnDestroy() {
    this.#intervalSubscription?.unsubscribe();
  }
  /** @nocollapse */
  static ɵfac = function ElapsedDateDirective_Factory(t) {
    return new (t || ElapsedDateDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.TranslocoService));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: ElapsedDateDirective,
    selectors: [["", "elapsedDate", ""]],
    inputs: {
      value: "value",
      update: "update",
      updateInterval: "updateInterval",
      dateFormat: "dateFormat",
      withoutSuffix: "withoutSuffix"
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ElapsedDateDirective, [{
    type: Directive,
    args: [{
      selector: '[elapsedDate]'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1$1.TranslocoService
  }], {
    value: [{
      type: Input
    }],
    update: [{
      type: Input
    }],
    updateInterval: [{
      type: Input
    }],
    dateFormat: [{
      type: Input
    }],
    withoutSuffix: [{
      type: Input
    }]
  });
})();
class EmojiTooltipDirective {
  overlayPositionBuilder;
  elementRef;
  overlay;
  title = 'Pick your emoji…';
  pickerClass = 'emoji-picker';
  emoji = 'point_up';
  virtualize = true;
  toggleTooltip = new EventEmitter();
  emojiClick = new EventEmitter();
  _overlayRef;
  _toolTipRef;
  _mouseX = 0;
  _mouseY = 0;
  constructor(overlayPositionBuilder, elementRef, overlay) {
    this.overlayPositionBuilder = overlayPositionBuilder;
    this.elementRef = elementRef;
    this.overlay = overlay;
    document.addEventListener('mousemove', this.onMouseUpdate, false);
    document.addEventListener('mouseenter', this.onMouseUpdate, false);
    document.addEventListener('click', this.onDocumentClick, false);
  }
  get elementHovered() {
    return document.elementsFromPoint(this._mouseX, this._mouseY).some(element => element === this.elementRef.nativeElement || element === this._toolTipRef?.location.nativeElement);
  }
  show() {
    if (this._overlayRef.hasAttached()) {
      return;
    }
    // Create tooltip portal
    const tooltipPortal = new ComponentPortal(PickerComponent);
    // Attach tooltip portal to overlay
    this._overlayRef.detach();
    this._toolTipRef = this._overlayRef.attach(tooltipPortal);
    // Pass content to tooltip component instance
    this._toolTipRef.instance.title = this.title;
    this._toolTipRef.instance.emoji = this.emoji;
    this._toolTipRef.instance.virtualize = this.virtualize;
    this._toolTipRef.instance.emojiClick.subscribe(e => this.emojiClick.emit(e));
    this._toolTipRef.location.nativeElement.classList.add(this.pickerClass);
  }
  hide() {
    if (!this._overlayRef.hasAttached()) {
      return;
    }
    this._overlayRef.detach();
    this._toolTipRef?.destroy();
    this._toolTipRef = null;
  }
  ngOnInit() {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([{
      originX: 'start',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top'
    }]);
    this._overlayRef = this.overlay.create({
      positionStrategy
    });
    this.toggleTooltip.subscribe(this.toggle.bind(this));
  }
  ngOnDestroy() {
    document.removeEventListener('mousemove', this.onMouseUpdate, false);
    document.removeEventListener('mouseenter', this.onMouseUpdate, false);
    document.removeEventListener('click', this.onDocumentClick, false);
  }
  onDocumentClick = e => {
    if (this._toolTipRef && !this.elementHovered) {
      this.hide();
    }
  };
  onMouseUpdate = e => {
    this._mouseX = e.pageX;
    this._mouseY = e.pageY;
  };
  toggle(toggle) {
    if (toggle) {
      this.show();
    } else {
      this.hide();
    }
  }
  /** @nocollapse */
  static ɵfac = function EmojiTooltipDirective_Factory(t) {
    return new (t || EmojiTooltipDirective)(i0.ɵɵdirectiveInject(i1$2.OverlayPositionBuilder), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$2.Overlay));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: EmojiTooltipDirective,
    selectors: [["", "emojiTooltip", ""]],
    hostBindings: function EmojiTooltipDirective_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("click", function EmojiTooltipDirective_click_HostBindingHandler() {
          return ctx.show();
        });
      }
    },
    inputs: {
      title: "title",
      pickerClass: "pickerClass",
      emoji: "emoji",
      virtualize: "virtualize",
      toggleTooltip: "toggleTooltip"
    },
    outputs: {
      emojiClick: "emojiClick"
    },
    exportAs: ["emojiTooltip"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EmojiTooltipDirective, [{
    type: Directive,
    args: [{
      selector: '[emojiTooltip]',
      exportAs: 'emojiTooltip'
    }]
  }], () => [{
    type: i1$2.OverlayPositionBuilder
  }, {
    type: i0.ElementRef
  }, {
    type: i1$2.Overlay
  }], {
    title: [{
      type: Input
    }],
    pickerClass: [{
      type: Input
    }],
    emoji: [{
      type: Input
    }],
    virtualize: [{
      type: Input
    }],
    toggleTooltip: [{
      type: Input
    }],
    emojiClick: [{
      type: Output
    }],
    show: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();

/**
 * Directive for detecting element focused state.
 *
 * @example
 * referencing the directive:
 * ```
 *     <input focused #isFocused="hasFocus">
 *     <div>has focus: {{isFocused.hasFocus}}</div>
 * ```
 * binding to the event:
 * ```
 *     <input focused (focusChange)="isFocused = $event">
 *     <div>has focus: {{isFocused}}</div>
 * ```
 */
class FocusedDirective {
  el;
  hasFocus = false;
  focusChange = new EventEmitter();
  constructor(el) {
    this.el = el;
  }
  onBlur(e) {
    this.hasFocus = false;
    this.focusChange.emit(this.hasFocus);
  }
  onFocus(e) {
    this.hasFocus = true;
    this.focusChange.emit(this.hasFocus);
  }
  /** @nocollapse */
  static ɵfac = function FocusedDirective_Factory(t) {
    return new (t || FocusedDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: FocusedDirective,
    selectors: [["input", "focused", ""], ["textarea", "focused", ""]],
    hostBindings: function FocusedDirective_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("blur", function FocusedDirective_blur_HostBindingHandler($event) {
          return ctx.onBlur($event);
        })("focus", function FocusedDirective_focus_HostBindingHandler($event) {
          return ctx.onFocus($event);
        });
      }
    },
    exportAs: ["hasFocus"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FocusedDirective, [{
    type: Directive,
    args: [{
      selector: 'input[focused],textarea[focused]',
      exportAs: 'hasFocus'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    onBlur: [{
      type: HostListener,
      args: ['blur', ['$event']]
    }],
    onFocus: [{
      type: HostListener,
      args: ['focus', ['$event']]
    }]
  });
})();
class MatMultilineTabDirective {
  elementRef;
  matTabGroup;
  class = true;
  onDestroy = new Subject();
  resizeObserver;
  resizeTimeout;
  constructor(elementRef, matTabGroup) {
    this.elementRef = elementRef;
    this.matTabGroup = matTabGroup;
    this.matTabGroup.selectedTabChange.pipe(takeUntil(this.onDestroy)).subscribe(() => this.updateInkBarPosition());
    this.resizeObserver = new ResizeObserver(() => {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => this.updateInkBarPosition(), 100);
    });
  }
  ngOnInit() {
    this.resizeObserver.observe(this.elementRef.nativeElement.querySelector('.mat-mdc-tab-header:first-child'));
  }
  ngOnDestroy() {
    this.resizeObserver && this.resizeObserver.disconnect();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
  updateInkBarPosition() {
    const headerElement = this.elementRef.nativeElement.querySelector('.mat-mdc-tab-header:first-child');
    const activeTabElement = headerElement.querySelector('.mdc-tab--active');
    const inkBarElement = headerElement.querySelector('.mdc-tab-indicator');
    const listElement = headerElement.querySelector('.mat-mdc-tab-list');
    const bottom = listElement.offsetHeight - (activeTabElement.offsetTop + activeTabElement.offsetHeight);
    inkBarElement.style.bottom = bottom + 'px';
    inkBarElement.style.left = activeTabElement.offsetLeft + 'px';
  }
  /** @nocollapse */
  static ɵfac = function MatMultilineTabDirective_Factory(t) {
    return new (t || MatMultilineTabDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$3.MatTabGroup));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: MatMultilineTabDirective,
    selectors: [["", "matMultilineTab", ""]],
    hostVars: 2,
    hostBindings: function MatMultilineTabDirective_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("mat-tab-multiline", ctx.class);
      }
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatMultilineTabDirective, [{
    type: Directive,
    args: [{
      selector: '[matMultilineTab]'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1$3.MatTabGroup
  }], {
    class: [{
      type: HostBinding,
      args: ['class.mat-tab-multiline']
    }]
  });
})();
class NativeElementInjectorDirective {
  el;
  control;
  model;
  constructor(el, control, model) {
    this.el = el;
    this.control = control;
    this.model = model;
  }
  ngOnInit() {
    if (!!this.model) {
      this.model.control.nativeElement = this.el.nativeElement;
    } else {
      this.control.control.nativeElement = this.el.nativeElement;
    }
  }
  /** @nocollapse */
  static ɵfac = function NativeElementInjectorDirective_Factory(t) {
    return new (t || NativeElementInjectorDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$4.NgControl), i0.ɵɵdirectiveInject(i1$4.NgModel, 8));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NativeElementInjectorDirective,
    selectors: [["", "formControl", ""], ["", "formControlName", ""]]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NativeElementInjectorDirective, [{
    type: Directive,
    args: [{
      selector: '[formControl], [formControlName]'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1$4.NgControl
  }, {
    type: i1$4.NgModel,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
class NativeElementInjectorModule {
  /** @nocollapse */static ɵfac = function NativeElementInjectorModule_Factory(t) {
    return new (t || NativeElementInjectorModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NativeElementInjectorModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NativeElementInjectorModule, [{
    type: NgModule,
    args: [{
      declarations: [NativeElementInjectorDirective],
      imports: [CommonModule],
      exports: [NativeElementInjectorDirective]
    }]
  }], null, null);
})();
class RenderIfVisibleDirective {
  vcRef;
  tplRef;
  minWidth = 40;
  minHeight = 40;
  rootMargin = 0;
  marginRoot;
  className;
  #intersectionObserver;
  #mutationObserver;
  #alreadyRendered = false;
  constructor(vcRef, tplRef) {
    this.vcRef = vcRef;
    this.tplRef = tplRef;
  }
  ngAfterViewInit() {
    const templateElement = this.vcRef.element.nativeElement;
    this.setMinWidthHeight(templateElement.parentElement);
    this.observeIntersection(templateElement.parentElement);
    this.observeCLassMutation(templateElement.parentElement);
  }
  ngOnDestroy() {
    this.#intersectionObserver.disconnect();
    this.#mutationObserver.disconnect();
  }
  observeIntersection(targetElement) {
    this.#intersectionObserver = new IntersectionObserver(entries => entries.forEach(entry => this.renderContents(entry.isIntersecting)), {
      root: this.marginRoot,
      rootMargin: `${this.rootMargin}px`,
      threshold: [0]
    });
    this.#intersectionObserver.observe(targetElement);
  }
  observeCLassMutation(targetElement) {
    this.#mutationObserver = new MutationObserver(() => {
      if (targetElement.classList.contains(this.className)) {
        this.renderContents(true);
      }
    });
    this.#mutationObserver.observe(targetElement, {
      attributeFilter: ['class']
    });
  }
  renderContents(isInView) {
    if (isInView && !this.#alreadyRendered) {
      this.vcRef.clear();
      this.vcRef.createEmbeddedView(this.tplRef);
      this.#alreadyRendered = true;
    }
  }
  setMinWidthHeight(el) {
    // preserve parent width and height
    const style = window.getComputedStyle(el);
    const [width, height] = [parseInt(style.width, 10), parseInt(style.height, 10)];
    /* eslint-disable @typescript-eslint/no-unused-expressions */
    !width && (el.style.minWidth = `${this.minWidth}px`);
    !height && (el.style.minHeight = `${this.minHeight}px`);
    /* eslint-enable @typescript-eslint/no-unused-expressions */
  }
  /** @nocollapse */
  static ɵfac = function RenderIfVisibleDirective_Factory(t) {
    return new (t || RenderIfVisibleDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: RenderIfVisibleDirective,
    selectors: [["", "renderIfVisible", ""]],
    inputs: {
      minWidth: "minWidth",
      minHeight: "minHeight",
      rootMargin: "rootMargin",
      marginRoot: "marginRoot",
      className: "className"
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RenderIfVisibleDirective, [{
    type: Directive,
    args: [{
      selector: '[renderIfVisible]'
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }, {
    type: i0.TemplateRef
  }], {
    minWidth: [{
      type: Input
    }],
    minHeight: [{
      type: Input
    }],
    rootMargin: [{
      type: Input
    }],
    marginRoot: [{
      type: Input
    }],
    className: [{
      type: Input
    }]
  });
})();
class DefaultImageModule {
  /** @nocollapse */static ɵfac = function DefaultImageModule_Factory(t) {
    return new (t || DefaultImageModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: DefaultImageModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultImageModule, [{
    type: NgModule,
    args: [{
      declarations: [DefaultImageDirective],
      exports: [DefaultImageDirective]
    }]
  }], null, null);
})();
class DateFormatPipe {
  _translocoService;
  static _translationsInitialized = new Map();
  constructor(_translocoService) {
    this._translocoService = _translocoService;
    const activeLang = _translocoService.getActiveLang();
    if (!DateFormatPipe._translationsInitialized.has(activeLang)) {
      moment.updateLocale(activeLang, {
        /* eslint-disable @typescript-eslint/naming-convention */
        longDateFormat: {
          LT: _translocoService.translate('FORMAT.DATE.TIME_OF_DAY', {}, activeLang),
          LTS: 'h:mm:ss A',
          L: _translocoService.translate('FORMAT.DATE.SHORT_DATE', {}, activeLang),
          LL: 'MMMM Do YYYY',
          LLL: 'MMMM Do YYYY LT',
          LLLL: 'dddd, MMMM Do YYYY LT'
        },
        months: [..._translocoService.translate('FORMAT.DATE.MONTHS', {}, activeLang).split('|')],
        monthsShort: [..._translocoService.translate('FORMAT.DATE.MONTHS_SHORT', {}, activeLang).split('|')],
        weekdays: [..._translocoService.translate('FORMAT.DATE.WEEKDAYS', {}, activeLang).split('|')],
        weekdaysShort: [..._translocoService.translate('FORMAT.DATE.WEEKDAYS_SHORT', {}, activeLang).split('|')]
        /* eslint-enable @typescript-eslint/naming-convention */
      });
      DateFormatPipe._translationsInitialized.set(activeLang, true);
    }
  }
  transform(value, dateFormat) {
    const date = value ? moment(value) : null;
    return date?.isValid() ? date.locale(this._translocoService.getActiveLang()).format(dateFormat) : value;
  }
  /** @nocollapse */
  static ɵfac = function DateFormatPipe_Factory(t) {
    return new (t || DateFormatPipe)(i0.ɵɵdirectiveInject(i1$1.TranslocoService, 16));
  };
  /** @nocollapse */
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "dateFormat",
    type: DateFormatPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateFormatPipe, [{
    type: Pipe,
    args: [{
      name: 'dateFormat'
    }]
  }], () => [{
    type: i1$1.TranslocoService
  }], null);
})();
class DateSuffixPipe {
  transform(value) {
    let suffix = 'th';
    const day = value;
    if (day === '1' || day === '21' || day === '31') {
      suffix = 'st';
    } else if (day === '2' || day === '22') {
      suffix = 'nd';
    } else if (day === '3' || day === '23') {
      suffix = 'rd';
    }
    return suffix;
  }
  /** @nocollapse */
  static ɵfac = function DateSuffixPipe_Factory(t) {
    return new (t || DateSuffixPipe)();
  };
  /** @nocollapse */
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "dateSuffix",
    type: DateSuffixPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateSuffixPipe, [{
    type: Pipe,
    args: [{
      name: 'dateSuffix'
    }]
  }], null, null);
})();
class EmojiService {
  emojiSearch;
  // eslint-disable-next-line max-len
  emojiMatch = emojiRegex();
  colonPairMatch = /(\:)(\w|\+|\-)+(\:)/;
  constructor(emojiSearch) {
    this.emojiSearch = emojiSearch;
  }
  /**
   * Turns a string with colon emojis into native form.
   *
   * @param s The string to convert into native emoji format
   */
  colonsToNative(s) {
    return this.replace(s, this.colonPairMatch, matched => this.mapStringToEmoji(matched)).replace(/\<\>\<\>/, ':');
  }
  removeColons(s) {
    return this.replace(s, this.colonPairMatch, matched => '').replace(/\<\>\<\>/, '');
  }
  /**
   * Turns a string with native emojis into a colon form and removes unknown emojis and ZWJ Sequences.
   *
   * @param s The string to convert into colon emoji format
   */
  nativeEmojiToColons(s) {
    return this.replace(s, this.emojiMatch, matched => this.mapEmojiToString(matched) || '');
  }
  removeNative(s) {
    return this.replace(s, this.emojiMatch, matched => '');
  }
  remove(s) {
    return this.removeNative(this.removeColons(s));
  }
  transformColonToNative(formControl, input) {
    return formControl.valueChanges.pipe(tap(value => {
      if (!value) {
        return;
      }
      const selectionStart = input.selectionStart ?? 0;
      const newValue = this.colonsToNative(value);
      if (value !== newValue) {
        formControl.setValue(newValue);
        const testText = value.slice(0, (selectionStart || 1) - 1);
        const lastColPos = testText.lastIndexOf(':');
        input.selectionStart = lastColPos + 2;
        input.selectionEnd = lastColPos + 2;
      }
    }));
  }
  insert(emoji, input) {
    let char;
    if (typeof emoji === 'string') {
      char = emoji;
    } else {
      char = emoji.emoji.native;
    }
    input.focus();
    if (document.execCommand) {
      const event = new Event('input');
      document.execCommand('insertText', false, char);
      return;
    }
    // insert emoji on caret position
    const [start, end] = [input.selectionStart, input.selectionEnd];
    input.setRangeText(char, start, end, 'end');
  }
  replace(s, colonPairMatch, replacer) {
    const regExp = new RegExp(colonPairMatch, 'gm');
    let regExpExecArray;
    while (regExpExecArray = regExp.exec(s)) {
      s = s.replace(regExp, replacer);
    }
    return s;
  }
  mapEmojiToString(emoji) {
    switch (emoji) {
      case '🅰':
        return ':a:';
      case '🅱':
        return ':b:';
      case '❤':
        return ':heart:';
      case '☺':
        return ':relaxed:';
      default:
        {
          const emojiList = Object.values(this.emojiSearch.emojisList);
          return emojiList.find(e => e.native === emoji)?.colons;
        }
    }
  }
  mapStringToEmoji(emojiString) {
    const emojiList = Object.values(this.emojiSearch.emojisList);
    let result = emojiList.find(e => e.colons === emojiString)?.native;
    if (result === undefined) {
      result = emojiString.replace(/\:/, '<><>');
    }
    return result;
  }
  /** @nocollapse */
  static ɵfac = function EmojiService_Factory(t) {
    return new (t || EmojiService)(i0.ɵɵinject(i1$5.EmojiSearch));
  };
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: EmojiService,
    factory: EmojiService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EmojiService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$5.EmojiSearch
  }], null);
})();
class EmojiPipe {
  _emojiService;
  constructor(_emojiService) {
    this._emojiService = _emojiService;
  }
  transform(value, ...args) {
    return value ? this._emojiService.colonsToNative(value) : value;
  }
  /** @nocollapse */
  static ɵfac = function EmojiPipe_Factory(t) {
    return new (t || EmojiPipe)(i0.ɵɵdirectiveInject(EmojiService, 16));
  };
  /** @nocollapse */
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "emoji",
    type: EmojiPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EmojiPipe, [{
    type: Pipe,
    args: [{
      name: 'emoji'
    }]
  }], () => [{
    type: EmojiService
  }], null);
})();

/**
 * Finds an object from given source using the given key - value pairs
 */
class FindByKeyPipe {
  /**
   * Constructor
   */
  constructor() {}
  /**
   * Transform
   *
   * @param value A string or an array of strings to find from source
   * @param key Key of the object property to look for
   * @param source Array of objects to find from
   */
  transform(value, key, source) {
    // If the given value is an array of strings...
    if (Array.isArray(value)) {
      return value.map(item => source.find(sourceItem => sourceItem[key] === item));
    }
    // If the value is a string...
    return source.find(sourceItem => sourceItem[key] === value);
  }
  /** @nocollapse */
  static ɵfac = function FindByKeyPipe_Factory(t) {
    return new (t || FindByKeyPipe)();
  };
  /** @nocollapse */
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "findByKey",
    type: FindByKeyPipe,
    pure: false
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FindByKeyPipe, [{
    type: Pipe,
    args: [{
      name: 'findByKey',
      pure: false
    }]
  }], () => [], null);
})();
class HumanizedDatePipe {
  transform(value, ...args) {
    if (value == null) {
      return;
    }
    return moment(value).calendar(null, {
      sameDay: '',
      nextDay: '[tomorrow]',
      nextWeek: '[this] dddd',
      lastDay: '[yesterday]',
      lastWeek: '[last] dddd',
      sameElse: 'DD/MM/YYYY'
    });
  }
  /** @nocollapse */
  static ɵfac = function HumanizedDatePipe_Factory(t) {
    return new (t || HumanizedDatePipe)();
  };
  /** @nocollapse */
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "humanizedDate",
    type: HumanizedDatePipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HumanizedDatePipe, [{
    type: Pipe,
    args: [{
      name: 'humanizedDate'
    }]
  }], null, null);
})();
class LikesPipe {
  _translocoService;
  constructor(_translocoService) {
    this._translocoService = _translocoService;
  }
  transform(value, ...args) {
    if (value == null) {
      return;
    }
    let translateKey;
    if (value === 1) {
      translateKey = 'VIDEO.COMMENT_LIKES.SINGLE';
    } else if (value < 1000) {
      translateKey = 'VIDEO.COMMENT_LIKES.BELOW_1K';
    } else if (value < 1000000) {
      translateKey = 'VIDEO.COMMENT_LIKES.BELOW_MIL';
      value = value / 1000;
    } else {
      translateKey = 'VIDEO.COMMENT_LIKES.BELOW_MRD';
      value = value / 1000000;
    }
    return this._translocoService.translate(translateKey, {
      count: value.toLocaleString(this._translocoService.getActiveLang())
    });
  }
  /** @nocollapse */
  static ɵfac = function LikesPipe_Factory(t) {
    return new (t || LikesPipe)(i0.ɵɵdirectiveInject(i1$1.TranslocoService, 16));
  };
  /** @nocollapse */
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "likes",
    type: LikesPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LikesPipe, [{
    type: Pipe,
    args: [{
      name: 'likes'
    }]
  }], () => [{
    type: i1$1.TranslocoService
  }], null);
})();
class MarkedPipe {
  // Set the options for the markdown renderer
  constructor() {
    marked.setOptions({
      renderer: new marked.Renderer(),
      //mangle     : true,
      pedantic: false,
      gfm: true,
      breaks: true
      //smartLists : true,
      //smartypants: false,
      //xhtml      : false,
    });
  }
  transform(value, ...args) {
    if (value && value.length > 0) {
      return AppInjector.get(DomSanitizer).bypassSecurityTrustHtml(marked(value));
    }
    return value;
  }
  /** @nocollapse */
  static ɵfac = function MarkedPipe_Factory(t) {
    return new (t || MarkedPipe)();
  };
  /** @nocollapse */
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "marked",
    type: MarkedPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkedPipe, [{
    type: Pipe,
    args: [{
      name: 'marked'
    }]
  }], () => [], null);
})();

// noinspection PointlessArithmeticExpressionJS
const semVerRexExp = new RegExp(
// eslint-disable-next-line max-len
'^(?<major>0|[1-9]\\d*)\\.(?<minor>0|[1-9]\\d*)\\.(?<patch>0|[1-9]\\d*)(?:-(?<prerelease>(?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\\.(?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\\+(?<buildmetadata>[0-9a-zA-Z-]+(?:\\.[0-9a-zA-Z-]+)*))?$');
const bitMaskRegExp = new RegExp('^(([01]?[0-9][0-9]?|2[0-4][0-9]|25[0-5])\\.){2}([01]?[0-9][0-9]?|2[0-4][0-9]|25[0-5])$');
class Version {
  major;
  minor;
  patch;
  prerelease;
  buildmetadata;
  constructor(data) {
    if (typeof data === 'string') {
      data = Version.parse(data);
    } else if (typeof data === 'number') {
      const parts = this.unmaskVersion(data).split('.');
      this.major = parseInt(parts[0], 10);
      this.minor = parseInt(parts[1], 10);
      this.patch = parseInt(parts[2], 10);
      return;
    }
    this.major = data?.major ?? 0;
    this.minor = data?.minor ?? 0;
    this.patch = data?.patch ?? 0;
    this.prerelease = data?.prerelease;
    this.buildmetadata = data?.buildmetadata;
  }
  /**
   * Parses the provided version string and returns a Version object.
   *
   * @param text
   * @throws {VersionException}
   */
  static parse(text) {
    const data = {
      major: 0,
      minor: 0,
      patch: 0
    };
    // remove trailing zeros from every version part
    if (text.includes('.')) {
      text = text.split('.').map((versionPart, i) => {
        const versionNumber = parseInt(versionPart, 10);
        return isNumber(versionNumber) ? versionNumber.toString() : versionPart.replace(/0+(?=\d+)/, '');
      }).join('.');
    }
    const version = semVerRexExp.exec(text);
    if (!version) {
      throw new VersionException('Invalid semVer version string. ' + text);
    }
    for (const _versionPart in version.groups) {
      if (!version.groups[_versionPart]) {
        continue;
      }
      switch (_versionPart) {
        case 'major':
          data.major = parseInt(version.groups[_versionPart], 10);
          break;
        case 'minor':
          data.minor = parseInt(version.groups[_versionPart], 10);
          break;
        case 'patch':
          data.patch = parseInt(version.groups[_versionPart], 10);
          break;
        case 'prerelease':
          data.prerelease = version.groups[_versionPart];
          break;
        case 'buildmetadata':
          data.buildmetadata = version.groups[_versionPart];
          break;
      }
    }
    return new Version(data);
  }
  static tryParse(text) {
    if (!text || !text?.length) {
      return null;
    }
    try {
      return Version.parse(text);
    } catch (e) {
      return null;
    }
  }
  // noinspection JSUnusedGlobalSymbols
  toLong() {
    for (const _version of [{
      name: 'major',
      part: this.major
    }, {
      name: 'minor',
      part: this.minor
    }, {
      name: 'patch',
      part: this.patch
    }]) {
      if (!isNumber(_version.part) || _version.part < 0 || _version.part > 255) {
        throw new ArgumentException('Version must be type of number and range between 0-255.', _version.name);
      }
    }
    return this.maskVersion(`${this.major}.${this.minor}.${this.patch}`);
  }
  toString() {
    let version = [this.major, this.minor, this.patch].join('.');
    if (this.prerelease) {
      version += `-${this.prerelease}`;
    }
    if (this.buildmetadata) {
      version += `+${this.buildmetadata}`;
    }
    return version;
  }
  /**
   * @param version should be a string in the format of 'vX.Y.Z' where X, Y, Z are
   * For example, 'v1.2.3' represents version 1.2.3.
   * @param maxBits is the number of bits used to represent the number each version number: [vMAJOR.MINOR.PATCH].
   * 8 bits are enough to represent the number [v0.0.0] to [v255.255.255].
   * @returns An unique integer representing the version.
   */
  maskVersion(version, maxBits = 8) {
    const versions = version.replace('v', '').split('.').map(e => Number(e));
    const major = versions[0];
    const minor = versions[1];
    const patch = versions[2];
    /* eslint-disable no-bitwise */
    return major << maxBits * 2 | minor << maxBits * 1 | patch << maxBits * 0;
    /* eslint-enable no-bitwise */
  }
  /**
   * @param version should be the integer returned by [maskVersion].
   * @param maxBits is the number of bits used to represent the number each version number: [vMAJOR.MINOR.PATCH].
   * 8 bits are enough to represent the number [v0.0.0] to [v255.255.255].
   * @return the original string representing the version.
   */
  unmaskVersion(version, maxBits = 8) {
    /* eslint-disable no-bitwise */
    const major = version >> maxBits * 2 & (1 << maxBits) - 1;
    const minor = version >> maxBits * 1 & (1 << maxBits) - 1;
    const patch = version >> maxBits * 0 & (1 << maxBits) - 1;
    /* eslint-enable no-bitwise */
    return `${major}.${minor}.${patch}`;
  }
}
class VersionException extends Exception {
  constructor(message, messageTemplateValues, innerException) {
    super(message, messageTemplateValues, innerException);
    VersionException.setPrototype(this);
  }
}
class VersionPipe {
  // Set the options for the markdown renderer
  constructor() {}
  transform(value, ...args) {
    if (value && isNumber$1(+value) || value.length > 0 && value.indexOf('.')) {
      try {
        value = `${new Version(isNumber$1(+value) ? Number(value) : value)}`;
      } catch {}
    }
    return value;
  }
  /** @nocollapse */
  static ɵfac = function VersionPipe_Factory(t) {
    return new (t || VersionPipe)();
  };
  /** @nocollapse */
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "version",
    type: VersionPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VersionPipe, [{
    type: Pipe,
    args: [{
      name: 'version'
    }]
  }], () => [], null);
})();

/**
 * Sanitizes a value by removing potentially harmful content.
 *
 * This pipe is used to sanitize user input or untrusted data to prevent
 * cross-site scripting (XSS) attacks and other security vulnerabilities.
 *
 * The pipe performs the following sanitization steps:
 * - Decodes URL-encoded characters
 * - Removes HTML tags, script tags, SVG tags, and MathML tags
 * - Removes query parameters
 * - Removes JavaScript event handlers and inline scripts
 * - Removes CSS expressions and inline styles
 * - Removes comments
 * - Removes JavaScript protocol handlers
 * - Removes data URIs and unwanted protocols
 * - Removes base64-encoded data
 *
 * @param value The value to be sanitized.
 * @returns The sanitized value as a string.
 *
 * @example
 *
 * <div>{{ unsafeValue | sanitizer }}</div>
 *
 */
class SanitizerPipe {
  transform(value) {
    if (!value) {
      return value;
    }
    switch (typeof value) {
      case 'string':
        // Decode URL-encoded characters
        value = decodeURIComponent(value);
        // Remove HTML tags
        value = value.replace(/<[^>]*>/g, '');
        // Remove script tags and their content
        value = value.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
        // Remove SVG and MathML tags
        value = value.replace(/<(?:svg|math)[^>]*>.*?<\/(?:svg|math)>/gi, '');
        // Remove query parameters
        value = value.replace(/\?[^>]*/g, '');
        // Remove JavaScript event handlers and inline scripts
        value = value.replace(/on\w+\s*=\s*(?:"[^"]*"|'[^']*'|[^>\s]*)/gi, '');
        // Remove attributes that start with "on" followed by any word characters
        value = value.replace(/\s+on\w+(?:\s*=\s*(?:"[^"]*"|'[^']*'|[^>\s]*))?/gi, '');
        // Remove CSS expressions and inline styles
        value = value.replace(/(?:expression|url|javascript|vbscript|data|mocha|livescript)\s*\([^>]*\)/gi, '');
        value = value.replace(/style\s*=\s*"[^"]*"/gi, '');
        value = value.replace(/style\s*=\s*'[^']*'/gi, '');
        // Remove comments
        value = value.replace(/<!--[\s\S]*?-->/g, '');
        // Remove JavaScript protocol handlers
        value = value.replace(/javascript:\s*(?:void\(0\)|alert\(.*?\)|window\.location|document\.cookie|document\.write)/gi, '');
        // Remove data URIs
        value = value.replace(/data:\s*(?:text\/html|image\/svg\+xml|application\/xhtml\+xml)/gi, '');
        // Remove unwanted protocols
        value = value.replace(/(?:ftp|telnet|mailto):/gi, '');
        // Remove base64-encoded data
        value = value.replace(/data:.*?base64,[\w+/=]*/gi, '');
        return value;
      case 'number':
        return value.toString();
      default:
        return value;
    }
  }
  /** @nocollapse */
  static ɵfac = function SanitizerPipe_Factory(t) {
    return new (t || SanitizerPipe)();
  };
  /** @nocollapse */
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "sanitizer",
    type: SanitizerPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SanitizerPipe, [{
    type: Pipe,
    args: [{
      name: 'sanitizer'
    }]
  }], null, null);
})();
const components = [AutocompleteOptionsDirective, BackButtonDirective, DateFormatPipe, DateSuffixPipe, ElapsedDateDirective, ElapsedDatePipe, EmojiPipe, EmojiTooltipDirective, FindByKeyPipe, FocusedDirective, HumanizedDatePipe, LikesPipe, MarkedPipe, MatMultilineTabDirective, RenderIfVisibleDirective, VersionPipe, SanitizerPipe];
class SharedModule {
  /** @nocollapse */static ɵfac = function SharedModule_Factory(t) {
    return new (t || SharedModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: SharedModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [PickerModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SharedModule, [{
    type: NgModule,
    args: [{
      imports: [PickerModule],
      declarations: components,
      exports: components
    }]
  }], null, null);
})();

/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols
/**
 * @description HTML AutoComplete Types
 * @link https://wiki.whatwg.org/wiki/Autocomplete_Types
 */
var AutoComplete;
(function (AutoComplete) {
  AutoComplete["Off"] = "off";
  AutoComplete["None"] = "off";
  /**
   * @description full name
   */
  AutoComplete["Name"] = "name";
  /**
   * @description prefix or title (Mr., Mrs. Dr., etc.)
   */
  AutoComplete["HonorificPrefix"] = "honorific-prefix";
  /**
   * @description given or first name
   */
  AutoComplete["GivenName"] = "given-name";
  /**
   * @description additional or middle name
   */
  AutoComplete["AdditionalName"] = "additional-name";
  /**
   * @description additional or middle name initial
   */
  AutoComplete["AdditionalNameInitial"] = "additional-name-initial";
  /**
   * @description family name, surname, or last name
   */
  AutoComplete["FamilyName"] = "family-name";
  /**
   * @description suffix (Jr., II, etc.)
   */
  AutoComplete["HonorificSuffix"] = "honorific-suffix";
  /**
   * @description nickname
   */
  AutoComplete["Nickname"] = "nickname";
  /**
   * @description full street address condensed into one line
   */
  AutoComplete["StreetAddress"] = "street-address";
  /**
   * @description first line of street address
   */
  AutoComplete["AddressLine1"] = "address-line1";
  /**
   * @description second line of street address
   */
  AutoComplete["AddressLine2"] = "address-line2";
  /**
   * @description third line of street address
   */
  AutoComplete["AddressLine3"] = "address-line3";
  /**
   * @description The first administrative level in the address.
   */
  AutoComplete["AddressLevel1"] = "address-level1";
  /**
   * @description The second administrative level, in addresses with at least two of them.
   * In countries with two administrative levels, this would typically be the city, town, village, or other locality in which the address is located.
   */
  AutoComplete["AddressLevel2"] = "address-level2";
  /**
   * @description The third administrative level, in addresses with at least three administrative levels.
   */
  AutoComplete["AddressLevel3"] = "address-level3";
  /**
   * @description The finest-grained administrative level, in addresses which have four levels.
   */
  AutoComplete["AddressLevel4"] = "address-level4";
  /**
   * @description locality or city
   */
  AutoComplete["Locality"] = "locality";
  /**
   * @description same as locality
   */
  AutoComplete["City"] = "city";
  /**
   * @description administrative area, state, province, or region
   */
  AutoComplete["AdministrativeArea"] = "administrative-area";
  /**
   * @description same as administrative-area
   */
  AutoComplete["State"] = "state";
  /**
   * @description same as administrative-area
   */
  AutoComplete["Province"] = "province";
  /**
   * @description same as administrative-area
   */
  AutoComplete["Region"] = "region";
  /**
   * @description postal or ZIP code
   */
  AutoComplete["PostalCode"] = "postal-code";
  /**
   * @description country name
   */
  AutoComplete["Country"] = "country";
  /**
   * @description country name
   */
  AutoComplete["CountryName"] = "country-name";
  /**
   * @description email address
   */
  AutoComplete["Email"] = "email";
  /**
   * @description full phone number, including country code
   */
  AutoComplete["Tel"] = "tel";
  /**
   * @description international country code
   */
  AutoComplete["TelCountryCode"] = "tel-country-code";
  /**
   * @description national phone number: full number minus country code
   */
  AutoComplete["TelNational"] = "tel-national";
  /**
   * @description area code
   */
  AutoComplete["TelAreaCode"] = "tel-area-code";
  /**
   * @description local phone number: full number minus country and area codes
   */
  AutoComplete["TelLocal"] = "tel-local";
  /**
   * @description phone extension number
   */
  AutoComplete["TelExtension"] = "tel-extension";
  /**
   * @description full fax number, including country code
   */
  AutoComplete["Fax"] = "fax";
  /**
   * @description international country code
   */
  AutoComplete["FaxCountryCode"] = "fax-country-code";
  /**
   * @description national fax number: full number minus country code
   */
  AutoComplete["FaxNational"] = "fax-national";
  /**
   * @description area code
   */
  AutoComplete["FaxAreaCode"] = "fax-area-code";
  /**
   * @description local fax number: full number minus country and area codes
   */
  AutoComplete["FaxLocal"] = "fax-local";
  /**
   * @description fax extension number
   */
  AutoComplete["FaxExtension"] = "fax-extension";
  /**
   * @description full name, as it appears on credit card
   */
  AutoComplete["CcName"] = "cc-name";
  /**
   * @description credit card number
   */
  AutoComplete["CcNumber"] = "cc-number";
  /**
   * @description month of expiration of credit card
   */
  AutoComplete["CcExpMonth"] = "cc-exp-month";
  /**
   * @description year of expiration of credit card (see note 3 below about formatting)
   */
  AutoComplete["CcExpYear"] = "cc-exp-year";
  /**
   * @description date of expiration of credit card (see note 4 below about formatting)
   */
  AutoComplete["CcExp"] = "cc-exp";
  /**
   * @description credit card security code
   */
  AutoComplete["CcCsc"] = "cc-csc";
  /**
   * @description preferred language
   */
  AutoComplete["Language"] = "language";
  /**
   * @description birthday (see note 4 below about formatting)
   */
  AutoComplete["Bday"] = "bday";
  /**
   * @description year of birthday (see note 3 below about formatting)
   */
  AutoComplete["BdayYear"] = "bday-year";
  /**
   * @description month of birthday
   */
  AutoComplete["BdayMonth"] = "bday-month";
  /**
   * @description day of birthday
   */
  AutoComplete["BdayDay"] = "bday-day";
  /**
   * @description company or organization
   */
  AutoComplete["Org"] = "organization";
  /**
   * @description user's position or title within company or organization
   */
  AutoComplete["OrganizationTitle"] = "organization-title";
  /**
   * @description sex or gender
   */
  AutoComplete["Sex"] = "sex";
  /**
   * @description gender identity
   */
  AutoComplete["GenderIdentity"] = "gender-identity";
  /**
   * @description Website URL
   */
  AutoComplete["Url"] = "url";
  /**
   * @description photo or avatar
   */
  AutoComplete["Photo"] = "photo";
})(AutoComplete || (AutoComplete = {}));

/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols
/**
 * @description The enterkeyhint content attribute is an enumerated attribute that specifies what action label (or icon) to present for the enter key on virtual keyboards.
 * This allows authors to customize the presentation of the enter key in order to make it more helpful for users.
 * @documentation https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-enterkeyhint-attribute
 */
var EnterKeyHints;
(function (EnterKeyHints) {
  /**
   * @description The user agent should present a cue for the operation 'enter', typically inserting a new line.
   */
  EnterKeyHints["Enter"] = "enter";
  /**
   * @description The user agent should present a cue for the operation 'done', typically meaning there is nothing more to input and the input method editor (IME) will be closed.
   */
  EnterKeyHints["Done"] = "done";
  /**
   * @description The user agent should present a cue for the operation 'go', typically meaning to take the user to the target of the text they typed.
   */
  EnterKeyHints["Go"] = "go";
  /**
   * @description The user agent should present a cue for the operation 'next', typically taking the user to the next field that will accept text.
   */
  EnterKeyHints["Next"] = "next";
  /**
   * @description The user agent should present a cue for the operation 'previous', typically taking the user to the previous field that will accept text.
   */
  EnterKeyHints["Previous"] = "previous";
  /**
   * @description The user agent should present a cue for the operation 'search', typically taking the user to the results of searching for the text they have typed.
   */
  EnterKeyHints["Search"] = "search";
  /**
   * @description The user agent should present a cue for the operation 'send', typically delivering the text to its target.
   */
  EnterKeyHints["Send"] = "send";
})(EnterKeyHints || (EnterKeyHints = {}));

/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols
/**
 * @description The inputmode content attribute is an enumerated attribute that specifies what kind of input mechanism would be most helpful for users entering content.
 * @documentation https://html.spec.whatwg.org/multipage/interaction.html#attr-inputmode
 */
var InputMode;
(function (InputMode) {
  /**
   * @description The user agent should not display a virtual keyboard. This keyword is useful for content that renders its own keyboard control.
   */
  InputMode["None"] = "none";
  /**
   * @description The user agent should display a virtual keyboard capable of text input in the user's locale.
   */
  InputMode["Text"] = "text";
  /**
   * @description The user agent should display a virtual keyboard capable of telephone number input.
   * This should including keys for the digits 0 to 9, the "#" character, and the "*" character. In some locales,
   * this can also include alphabetic mnemonic labels (e.g., in the US, the key labeled "2" is historically also labeled with the letters A, B, and C).
   */
  InputMode["Tel"] = "tel";
  /**
   * @description The user agent should display a virtual keyboard capable of text input in the user's locale,
   * with keys for aiding in the input of URLs, such as that for the "/" and "." characters and for quick input
   * of strings commonly found in domain names such as "www." or ".com".
   */
  InputMode["Url"] = "url";
  /**
   * @description The user agent should display a virtual keyboard capable of text input in the user's locale,
   * with keys for aiding in the input of email addresses, such as that for the "@" character and the "." character.
   */
  InputMode["Email"] = "email";
  /**
   * @description The user agent should display a virtual keyboard capable of numeric input. This keyword is useful for PIN entry.
   */
  InputMode["Numeric"] = "numeric";
  /**
   * @description The user agent should display a virtual keyboard capable of fractional numeric input. Numeric keys and the format separator for the locale should be shown.
   */
  InputMode["Decimal"] = "decimal";
  /**
   * @description The user agent should display a virtual keyboard optimized for search.
   */
  InputMode["Search"] = "search";
})(InputMode || (InputMode = {}));
class ElementPadding {
  left;
  top;
  right;
  bottom;
  constructor(element = document.body) {
    this.left = +ElementPadding.getNumericValue(element, 'paddingLeft');
    this.top = +ElementPadding.getNumericValue(element, 'paddingTop');
    this.right = +ElementPadding.getNumericValue(element, 'paddingRight');
    this.bottom = +ElementPadding.getNumericValue(element, 'paddingBottom');
  }
  get x() {
    return this.left + this.right;
  }
  get y() {
    return this.top + this.bottom;
  }
  static getNumericValue(element, key) {
    const match = window.getComputedStyle(element)[key].match(/\d+/g);
    return match ? Number(match[0]) : null;
  }
}
const CANCEL_ICON = new InjectionToken('CANCEL_ICON');
const DELETE_ICON = new InjectionToken('DELETE_ICON');
const SAVE_ICON = new InjectionToken('SAVE_ICON');

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
const getDateFormat = format => AppInjector.get(TranslocoService).translate('FORMAT.DATE.' + format);
const MOMENT_FORMATS = {
  parse: new class {
    get dateInput() {
      return getDateFormat('SHORT_DATE');
    }
  }(),
  display: new class {
    get dateInput() {
      return getDateFormat('SHORT_DATE');
    }
    get monthYearLabel() {
      return getDateFormat('MONTH_YEAR');
    }
    get dateA11yLabel() {
      return getDateFormat('SHORT_DATE');
    }
    get monthYearA11yLabel() {
      return getDateFormat('MONTH_YEAR_ALLY');
    }
  }()
};
const formatNumber = (value, decimals = 2) => {
  const locales = AppInjector.get(TranslocoService).getAvailableLangs().map(lang => typeof lang === 'string' ? lang : lang.id);
  const activeLang = AppInjector.get(TranslocoService).getActiveLang();
  locales.splice(locales.findIndex(locale => locale === activeLang), 1);
  locales.splice(0, 0, activeLang);
  return value?.toLocaleString(locales, {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals
  }) || '0';
};
const formatRange = (page, pageSize, length) => {
  const translocoService = AppInjector.get(TranslocoService);
  // eslint-disable-next-line eqeqeq
  if (length == 0 || pageSize == 0) {
    return translocoService.translate('FORMAT.PAGINATION.RANGE_EMPTY', {
      length
    });
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;
  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
  return translocoService.translate('FORMAT.PAGINATION.RANGE', {
    startIndex: startIndex + 1,
    endIndex,
    length
  });
};
let paginatorIntl;
const init = () => {
  const translocoService = AppInjector.get(TranslocoService);
  paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.getRangeLabel = formatRange;
  const setTranslations = async () => {
    const translate = key => firstValueFrom(translocoService.selectTranslate(key));
    paginatorIntl.itemsPerPageLabel = await translate('FORMAT.PAGINATION.ITEMS_PER_PAGE');
    paginatorIntl.firstPageLabel = await translate('FORMAT.PAGINATION.FIRST_PAGE');
    paginatorIntl.nextPageLabel = await translate('FORMAT.PAGINATION.NEXT_PAGE');
    paginatorIntl.previousPageLabel = await translate('FORMAT.PAGINATION.PREVIOUS_PAGE');
    paginatorIntl.lastPageLabel = await translate('FORMAT.PAGINATION.LAST_PAGE');
  };
  translocoService.langChanges$.subscribe(lang => {
    if (translocoService.getAvailableLangs().some(_lang => _lang === lang || _lang.id === lang)) {
      setTimeout(() => setTranslations());
    }
  });
  translocoService.events$.subscribe(event => {
    if (event.type === 'translationLoadSuccess') {
      setTranslations();
    }
  });
};
const getPaginatorIntl = () => {
  if (!paginatorIntl) {
    init();
  }
  return paginatorIntl;
};

/**
 * Returns the currently selected browser user language if contained
 * within the provided available languages or the provided default lang value.
 *
 * @param availableLanguages
 * @param defaultLanguage
 * @return
 */
const getUserLanguage = (availableLanguages, defaultLanguage = 'en') => {
  const regExpExecArray = /([a-z]{2})(-[A-Z]{2})?/.exec(navigator.language);
  let language = regExpExecArray && regExpExecArray.length > 1 ? regExpExecArray[1] : null;
  if (!language || !availableLanguages.includes(language)) {
    language = defaultLanguage;
  }
  return language;
};
class TranslocoHttpLoader {
  _httpClient;
  /**
   * Constructor
   */
  constructor(_httpClient) {
    this._httpClient = _httpClient;
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Get translation
   *
   * @param lang
   */
  getTranslation(lang) {
    return this._httpClient.get(`assets/i18n/${lang}.json`);
  }
  /** @nocollapse */
  static ɵfac = function TranslocoHttpLoader_Factory(t) {
    return new (t || TranslocoHttpLoader)(i0.ɵɵinject(i1$6.HttpClient));
  };
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: TranslocoHttpLoader,
    factory: TranslocoHttpLoader.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslocoHttpLoader, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$6.HttpClient
  }], null);
})();
class TranslocoCoreModule {
  _translocoService;
  constructor(_translocoService) {
    this._translocoService = _translocoService;
  }
  static forRoot(config) {
    return {
      ngModule: TranslocoCoreModule,
      providers: [{
        // Provide the default Transloco configuration
        provide: TRANSLOCO_CONFIG,
        useValue: translocoConfig(config)
      }]
    };
  }
  async mergeTranslations(transformLocation) {
    await Promise.all(this._translocoService.getAvailableLangs().map(async lang => {
      lang = typeof lang === 'string' ? lang : lang.id;
      this._translocoService.setTranslation(await lastValueFrom(this._translocoService.load(transformLocation(lang))), lang, {
        merge: true
      });
    }));
  }
  /** @nocollapse */
  static ɵfac = function TranslocoCoreModule_Factory(t) {
    return new (t || TranslocoCoreModule)(i0.ɵɵinject(i1$1.TranslocoService));
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: TranslocoCoreModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [{
      // Provide the default Transloco loader
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoHttpLoader
    }, {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      deps: [TranslocoService],
      useFactory: translocoService => () => {
        const defaultLang = translocoService.getDefaultLang();
        translocoService.setActiveLang(defaultLang);
        return lastValueFrom(translocoService.load(defaultLang));
      },
      multi: true
    }],
    imports: [TranslocoModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslocoCoreModule, [{
    type: NgModule,
    args: [{
      exports: [TranslocoModule],
      providers: [{
        // Provide the default Transloco loader
        provide: TRANSLOCO_LOADER,
        useClass: TranslocoHttpLoader
      }, {
        // Preload the default language before the app starts to prevent empty/jumping content
        provide: APP_INITIALIZER,
        deps: [TranslocoService],
        useFactory: translocoService => () => {
          const defaultLang = translocoService.getDefaultLang();
          translocoService.setActiveLang(defaultLang);
          return lastValueFrom(translocoService.load(defaultLang));
        },
        multi: true
      }]
    }]
  }], () => [{
    type: i1$1.TranslocoService
  }], null);
})();

// noinspection JSUnusedGlobalSymbols
class CustomRouteReuseStrategyService {
  storedRoutes = {};
  static createIdentifier(route) {
    // Build the complete path from the root to the input route
    const segments = route.pathFromRoot.map(r => r.url);
    const subpaths = [].concat(...segments).map(segment => segment.path);
    // Result: ${route_depth}-${path}
    return segments.length + '-' + subpaths.join('/');
  }
  shouldDetach(route) {
    return route.data['reuse'] || false;
  }
  store(route, handle) {
    const id = CustomRouteReuseStrategyService.createIdentifier(route);
    if (route.data['reuse'] && id.length > 0) {
      this.storedRoutes[id] = {
        handle,
        snapshot: route
      };
    }
  }
  shouldAttach(route) {
    const id = CustomRouteReuseStrategyService.createIdentifier(route);
    const storedObject = this.storedRoutes[id];
    const canAttach = !!route.routeConfig && !!storedObject;
    if (!canAttach) {
      return false;
    }
    const paramsMatch = this.compareObjects(route.params, storedObject.snapshot.params);
    const queryParamsMatch = this.compareObjects(route.queryParams, storedObject.snapshot.queryParams);
    /*console.log('deciding to attach...', route, 'does it match?');
    console.log('param comparison:', paramsMatch);
    console.log('query param comparison', queryParamsMatch);
    console.log(storedObject.snapshot, 'return: ', paramsMatch && queryParamsMatch);*/
    return paramsMatch && queryParamsMatch;
  }
  retrieve(route) {
    const id = CustomRouteReuseStrategyService.createIdentifier(route);
    if (!route.routeConfig || !this.storedRoutes[id]) {
      return null;
    }
    return this.storedRoutes[id].handle;
  }
  shouldReuseRoute(future, curr) {
    return future.routeConfig === curr.routeConfig;
  }
  compareObjects(base, compare) {
    // loop through all properties
    for (const baseProperty in {
      ...base,
      ...compare
    }) {
      // determine if comparison object has that property, if not: return false
      if (compare.hasOwnProperty(baseProperty)) {
        switch (typeof base[baseProperty]) {
          // if one is object and other is not: return false
          // if they are both objects, recursively call this comparison function
          case 'object':
            if (typeof compare[baseProperty] !== 'object' || !this.compareObjects(base[baseProperty], compare[baseProperty])) {
              return false;
            }
            break;
          // if one is function and other is not: return false
          // if both are functions, compare function.toString() results
          case 'function':
            if (typeof compare[baseProperty] !== 'function' || base[baseProperty].toString() !== compare[baseProperty].toString()) {
              return false;
            }
            break;
          // otherwise, see if they are equal using coercive comparison
          default:
            // tslint:disable-next-line triple-equals
            if (base[baseProperty] !== compare[baseProperty]) {
              return false;
            }
        }
      } else {
        return false;
      }
    }
    // returns true only after false HAS NOT BEEN returned through all loops
    return true;
  }
}
class HammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: {
      enable: false
    },
    rotate: {
      enable: false
    },
    swipe: {
      direction: Hammer.DIRECTION_ALL
    }
  };
  buildHammer(element) {
    const mc = new window.Hammer(element);
    for (const eventName in this.overrides) {
      if (eventName) {
        mc.get(eventName).set(this.overrides[eventName]);
      }
    }
    return mc;
  }
  /** @nocollapse */
  static ɵfac = /* @__PURE__ */(() => {
    let ɵHammerConfig_BaseFactory;
    return function HammerConfig_Factory(t) {
      return (ɵHammerConfig_BaseFactory || (ɵHammerConfig_BaseFactory = i0.ɵɵgetInheritedFactory(HammerConfig)))(t || HammerConfig);
    };
  })();
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: HammerConfig,
    factory: HammerConfig.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HammerConfig, [{
    type: Injectable
  }], null, null);
})();
const MEDIA_WATCHER_SERVICE = new InjectionToken('MediaWatcherService');

/**
 * Generated bundle index. Do not edit.
 */

export { AutoComplete, AutocompleteOptionsDirective, BackButtonDirective, CANCEL_ICON, CustomRouteReuseStrategyService, DELETE_ICON, DateFormatPipe, DateSuffixPipe, DefaultImageDirective, DefaultImageModule, ElapsedDateDirective, ElapsedDatePipe, ElementPadding, EmojiPipe, EmojiService, EmojiTooltipDirective, EnterKeyHints, FindByKeyPipe, FocusedDirective, HammerConfig, HumanizedDatePipe, InputMode, LikesPipe, MEDIA_WATCHER_SERVICE, MOMENT_FORMATS, MarkedPipe, MatMultilineTabDirective, NativeElementInjectorDirective, NativeElementInjectorModule, NavigateBackEventArgs, NavigationService, RenderIfVisibleDirective, SAVE_ICON, SanitizerPipe, SharedModule, ToggleFooterEventArgs, TranslocoCoreModule, Version, VersionException, VersionPipe, bitMaskRegExp, formatNumber, getPaginatorIntl, getUserLanguage, semVerRexExp };
