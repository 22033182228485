import { NgModule } from '@angular/core';
import { ContentModule } from '@swan/lib/content';
import { SwanVideoListService } from './swan-video-list.service';
import { VideoCommentService } from './video-comment.service';


@NgModule({
    imports  : [
        ContentModule,
    ],
    providers: [
        SwanVideoListService,
        VideoCommentService,
    ],
})
export class VideoModule
{

}
