<div id="settings" class="absolute left-2 top-5 right-0 bottom-0 overflow-y-scroll space-y-6">
    <div *ngFor="let _option of options.items | keyvalue: originalOrder" class="">
        <h4 *ngIf="_option.key !== 'empty'"
            class="font-semibold" transloco="{{ 'PROFILE.SETTINGS.CATEGORIES.' + _option.key }}"></h4>

        <ng-container [ngSwitch]="displayInterests">

            <mat-list *ngSwitchCase="false">
                <mat-list-item matRipple *ngFor="let _option of _option.value" class="setting pointer h-12"
                               [routerLink]="_option.link"
                               (click)="_option.action && _option.action()"
                               [ngSwitch]="_option.type">

                    <mat-icon matListIcon *ngIf="_option.icon" [svgIcon]="_option.icon"
                              class="text-black {{ _option.iconClass }}"></mat-icon>

                    <h4 matLine class="p-1 font-medium text-base whitespace-pre">{{ _option.name }}</h4>

                    <button mat-icon-button *ngSwitchCase="'link'">
                        <mat-icon matListIcon svgIcon="swan:navigation-chevron-right" class="text-gray-500"></mat-icon>
                    </button>

                    <mat-slide-toggle *ngSwitchCase="'toggle'" [(ngModel)]="_option.toggleValue"
                                      (change)="_option.toggle && _option.toggle(_option)">
                    </mat-slide-toggle>

                </mat-list-item>
            </mat-list>

            <lib-interests *ngSwitchCase="true" class="h-full"></lib-interests>

        </ng-container>

    </div>
</div>
