<div class="flex flex-col w-full">

    <div>
        <h2 transloco="PERSONALIZE.GENDER.WHAT_GENDER"></h2>
        <p class="pt-2" transloco="PERSONALIZE.GENDER.RELEVANT_CONTENT"></p>
    </div>

    <mat-selection-list class="pt-6 space-y-2" (selectionChange)="genderChanged($event)" [disabled]="disabled">

        <mat-list-option [selected]="gender === 'FEMALE'" [value]="'FEMALE'"
                         class="border rounded-md text-base tracking-wide">
            <span class="font-bold text-black" transloco="PERSONALIZE.GENDER.FEMALE"></span>
        </mat-list-option>

        <mat-list-option [selected]="gender === 'MALE'" [value]="'MALE'"
                         class="border rounded-md text-base tracking-wide">
            <span class="font-bold text-black" transloco="PERSONALIZE.GENDER.MALE"></span>
        </mat-list-option>

        <mat-list-option [selected]="gender === 'DIVERSE'" [value]="'DIVERSE'"
                         class="border rounded-md text-base tracking-wide">
            <span class="font-bold text-black" transloco="PERSONALIZE.GENDER.DIVERSE"></span>
        </mat-list-option>

    </mat-selection-list>

</div>
