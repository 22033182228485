import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideFuse } from '@fuse';
import { LocalStorageService, MockApiModule, StorageService, setAppInjector } from '@yukawa/chain-base-angular-client';
import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { mockApiServices } from 'app/mock-api';
import { MarkdownModule } from 'ngx-markdown';
import { CommonModule } from '@angular/common';
import { LayoutModule } from 'app/layout/layout.module';
import { MEDIA_WATCHER_SERVICE } from '@yukawa/chain-base-angular-comp/shared';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';



@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,

        MockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,
        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
    ],
    providers   : [
        provideFuse(appConfig),
        {
            provide: MEDIA_WATCHER_SERVICE,
            useClass: FuseMediaWatcherService,
        },
        {
            provide: StorageService,
            useClass: LocalStorageService
          }
    ],
    bootstrap   : [
        AppComponent,
    ],
})
export class AppModule
{
    constructor(injector: Injector)
    {
        setAppInjector(injector);
    }
}
