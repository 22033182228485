import { Injectable } from '@angular/core';
import { ProfilePersonalizeSteps } from '@swan/lib/profile';
import { Nullable } from 'simplytyped';


export interface IPersonalizeHost
{
    step: ProfilePersonalizeSteps;
    valid?: boolean;
    working?: boolean;
    hideNext?: boolean;
    lastStep?: boolean;
    scrollPadding?: boolean;

    next?(): Promise<boolean>;
}

export type NextHandler = (skip?: boolean) => Promise<boolean>;

@Injectable()
export class PersonalizeService
{
    private _personalizeHost: Nullable<IPersonalizeHost>;
    private _next!: NextHandler;

    constructor()
    {
    }

    public get personalizeHost(): Nullable<IPersonalizeHost>
    {
        return this._personalizeHost;
    }

    public set personalizeHost(value: Nullable<IPersonalizeHost>)
    {
        this._personalizeHost = value;
    }

    public get next(): NextHandler
    {
        return this._next;
    }

    public set next(value: NextHandler)
    {
        this._next = value;
    }

    public isValid(): boolean
    {
        return this._personalizeHost == null || (this._personalizeHost.valid ?? true);
    }

    public isWorking(): boolean
    {
        return this._personalizeHost != null && !!this._personalizeHost.working;
    }
}
