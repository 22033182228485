import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { fadeIn, fadeOut } from '@swan/lib/animations';
import { SwanVideoListService, VideoListConfig } from '@swan/lib/content/video';
import { ProfilePersonalizeSteps, ProfileService } from '@swan/lib/profile';
import { AnimationOptions } from 'ngx-lottie';
import { IPersonalizeHost, PersonalizeService } from '../personalize.service';
import { ProfileComponentBase } from '../profile-component-base';


@Component({
    selector   : 'lib-drum-roll',
    templateUrl: './drum-roll.component.html',
    styleUrls  : ['./drum-roll.component.scss'],
    animations : [fadeIn, fadeOut],
})
export class DrumRollComponent extends ProfileComponentBase implements OnInit, AfterViewInit, OnDestroy, IPersonalizeHost
{
    readonly step    = ProfilePersonalizeSteps.drumRoll;
    readonly working = true;

    readonly options: AnimationOptions = {
        path: '/assets/animations/swan_onboarding_lottie.json',
    };
    public heading!: string;
    public subHeadings!: Array<string>;
    public subHeadingIndex: number     = -1;

    #videoListsLoading!: Promise<void>;

    constructor(
        _profileService: ProfileService,
        private readonly _personalizeService: PersonalizeService,
        private readonly _translocoService: TranslocoService,
        private readonly _videoListService: SwanVideoListService,
    )
    {
        super(_profileService);
    }

    get hideNext(): boolean
    {
        return true;
    }

    get lastStep(): boolean
    {
        return true;
    }

    get lastSubHeading(): boolean
    {
        return this.subHeadingIndex === this.subHeadings.length - 1;
    }

    override ngOnInit(): void
    {
        super.ngOnInit();

        this.heading = this._translocoService.translate(
            'PERSONALIZE.DRUM_ROLL.SWAN_FEED',
            { name: this.profile.displayName || this.profile.username },
        );

        setTimeout(() =>
        {
            this._personalizeService.personalizeHost = this;
        });

        this.subHeadings = [
            this._translocoService.translate('PERSONALIZE.DRUM_ROLL.INTERESTS'),
            this._translocoService.translate('PERSONALIZE.DRUM_ROLL.GENDER'),
            this._translocoService.translate('PERSONALIZE.DRUM_ROLL.AGE'),
        ];
    }

    public ngAfterViewInit(): void
    {
        const interval = setInterval(async () =>
        {
            if (this.lastSubHeading) {
                clearInterval(interval);
                await this.#videoListsLoading;
                await this._personalizeService.next();
                return;
            }
            this.heading = this._translocoService.translate('PERSONALIZE.DRUM_ROLL.FINDING_CONTENT');
            this.subHeadingIndex++;
        }, 3000);

        this.#videoListsLoading = this.preloadVideoLists();
    }

    public ngOnDestroy(): void
    {
        this._personalizeService.personalizeHost = null;
    }

    private async preloadVideoLists(): Promise<void>
    {
        for (const _listType of SwanVideoListService.defaultVideoLists.keys()) {
            this._videoListService.addItemList(
                _listType,
                SwanVideoListService.defaultVideoLists.get(_listType) as VideoListConfig,
            );
        }

        return this._videoListService.load();
    }
}
