import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { SettingsComponent } from 'app/layout/common/settings/settings.component';


@NgModule({
    declarations: [
        SettingsComponent
    ],
    imports     : [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        RouterModule,
        FuseDrawerComponent,
        TranslocoModule,
    ],
    exports     : [
        SettingsComponent
    ]
})
export class SettingsModule
{
}
