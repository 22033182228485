import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { expandCollapse } from '@swan/lib/animations';
import { ProfilePersonalizeSteps, ProfileService } from '@swan/lib/profile';
import { IPersonalizeHost, PersonalizeService } from '../personalize.service';
import { ProfileComponentBase } from '../profile-component-base';


@Component({
    selector   : 'lib-consent',
    templateUrl: './consent.component.html',
    styleUrls  : ['./consent.component.scss'],
    animations : [expandCollapse],
})
export class ConsentComponent extends ProfileComponentBase implements OnInit, AfterViewInit, OnDestroy, IPersonalizeHost
{
    disabled: boolean    = false;
    showButtons: boolean = false;

    constructor(
        _profileService: ProfileService,
        private _personalizeService: PersonalizeService,
    )
    {
        super(_profileService);
    }

    get pushNotifications(): boolean
    {
        return !!this.profile.pushNotifications;
    }

    get valid(): boolean
    {
        return this.profile.pushNotifications != null;
    }

    get step(): ProfilePersonalizeSteps
    {
        return ProfilePersonalizeSteps.consent;
    }

    get working(): boolean
    {
        return this.disabled;
    }

    get hideNext(): boolean
    {
        return true;
    }

    override ngOnInit(): void
    {
        super.ngOnInit();
        setTimeout(() =>
        {
            this._personalizeService.personalizeHost = this;
        });
    }

    public ngAfterViewInit(): void
    {
        setTimeout(() =>
        {
            this.showButtons = true;
        }, 300);
    }

    public ngOnDestroy(): void
    {
        this._personalizeService.personalizeHost = null;
    }

    async imIn(): Promise<boolean>
    {
        this.profile.pushNotifications = true;
        return this._personalizeService.next();
    }

    async imOut(): Promise<boolean>
    {
        this.profile.pushNotifications = false;
        return this._personalizeService.next();
    }

    async maybeLater(): Promise<boolean>
    {
        return this._personalizeService.next(true);
    }

    async next(): Promise<boolean>
    {
        return this.saveProfile();
    }

    override async saveProfile(): Promise<boolean>
    {
        this.disabled = true;
        try {
            const result = await super.saveProfile();
            if (result) {
            }
            return result;
        }
        catch (error) {
            console.error(error);
            return false;
        }
        finally {
            this.disabled = false;
        }
    }
}
