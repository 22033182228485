
@switch (linkToProfile) {
  @case (true) {
    <a class="transition-none" (click)="avatarClick()">
      <ng-container *ngTemplateOutlet="userIconTemplate"></ng-container>
    </a>
  }
  @case (false) {
    <ng-container *ngTemplateOutlet="userIconTemplate"></ng-container>
  }
}


@if (displayVerified && verified) {
  <div class="static h-full w-full">
    <mat-icon class="icon-size-{{verifiedIconSize}}" svgIcon="swan:verified" [ngStyle]="{
        'top.px': verifiedTop,
        'left.px': verifiedLeft
    }"></mat-icon>
  </div>
}

<ng-template #userIconTemplate>
  @switch (showProfilePicture) {
    @case (false) {
      <svg #svg></svg>
    }
    @case (true) {
      @switch (profilePicture !== null) {
        @case (false) {
          <mat-icon
                        class="block m-auto text-gray-500" [svgIcon]="'swan:system-account-circle'" [ngStyle]="{
                     'width.px': size,
                     'height.px': size
                 }"></mat-icon>
        }
        @case (true) {
          @switch (swiperLazy) {
            @case (true) {
              <img [attr.data-src]="profilePicture" src="" alt="" @fadeIn
                class="object-cover rounded-full swiper-lazy"
                             [ngStyle]="{
                     'width.px': size,
                     'height.px': size
                 }">
            }
            @case (false) {
              <img [src]="profilePicture" alt="" @fadeIn
                class="object-cover rounded-full"
                             [ngStyle]="{
                     'width.px': size,
                     'height.px': size
                 }">
            }
          }
        }
      }
    }
  }
</ng-template>
