import { Content, ContentVideoFile, IVideoFile } from '@swan/lib/content';
import { VideoComment } from './video-comment.entity';


export class SwanVideoFile extends ContentVideoFile
{
    comments     = new Array<VideoComment>();
    commentCache = new Map<string, VideoComment>();

    public constructor(file: IVideoFile<Content>)
    {
        super({
            content : file.fileInfo,
            favorite: file.favorite,
            liked   : file.liked,
        });
    }

    refreshCommentData(): void
    {
        if (this.comments.length === 0) {
            return;
        }

        const hoursBetween = (time1: string, time2: string): number =>
            Math.floor(Math.abs(new Date(time1).valueOf() - new Date(time2).valueOf()) / 1000 / 60 / 60);

        for (let i = 0; i < this.comments.length; i++) {
            const authorID                  = this.comments[i].author.username;
            const timeOfMessage: string     = this.comments[i].timeOfMessage;
            const prevComment: VideoComment = this.comments[i - 1];
            const nextComment: VideoComment = this.comments[i + 1];

            // if the comment is proceeded by a different author's comment, or the time between this.comments
            // is significant, mark it as start of end of series, then start a new series proceeding.
            if (authorID !== this.comments[i + 1]?.author.username || hoursBetween(
                timeOfMessage,
                this.comments[i + 1]?.timeOfMessage,
            ) > 3) { // IDs match or long time
                this.comments[i].shouldShowAvatar = true;
                if (nextComment) {
                    nextComment.shouldShowTimestamp = true;
                }
            }
            else {
                this.comments[i].shouldShowAvatar = true;
                if (nextComment) {
                    nextComment.shouldShowTimestamp = false;
                }
            }

            // if the comment is preceeded by a non-content comment, mark it as start of series.
            if (this.comments[i].isBubbleComment && (!prevComment || !prevComment.isBubbleComment)) {
                this.comments[i].firstInSeries = true;
            }

            // if the comment is proceeded by a non-conent comment, mark it as end of series.
            if (this.comments[i].isBubbleComment && (!nextComment || !nextComment.isBubbleComment)) {
                this.comments[i].shouldShowAvatar = true;
            }

            // Link in original messages for replies
            if (this.comments[i].replyToId) {
                this.comments[i].originalComment = this.comments.find((tc: VideoComment) => tc.id === this.comments[i].replyToId) as VideoComment;
            }
        } // end for each comment...

        // make sure to show timestamp for first comment
        this.comments[0].shouldShowTimestamp = true;

        // make sure we show the avatar of the last comment...
        this.comments[this.comments.length - 1].shouldShowAvatar = true;
    }
}
