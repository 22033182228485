import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfilePersonalizeSteps, ProfileService } from '@swan/lib/profile';
import moment, { Moment } from 'moment';
import { Nullable } from 'simplytyped';
import { IPersonalizeHost, PersonalizeService } from '../personalize.service';
import { ProfileComponentBase } from '../profile-component-base';


@Component({
    selector   : 'lib-birthdate',
    templateUrl: './birthdate.component.html',
    styleUrls  : ['./birthdate.component.scss'],
})
export class BirthdateComponent extends ProfileComponentBase implements OnInit, OnDestroy, IPersonalizeHost
{
    birthdateForm!: FormGroup;

    constructor(
        _profileService: ProfileService,
        private _personalizeService: PersonalizeService,
    )
    {
        super(_profileService);
    }

    get day(): FormControl
    {
        return this.birthdateForm.get('day') as FormControl;
    }

    get month(): FormControl
    {
        return this.birthdateForm.get('month') as FormControl;
    }

    get year(): FormControl
    {
        return this.birthdateForm.get('year') as FormControl;
    }

    get maxYear(): number
    {
        return moment().subtract(0, 'year').year();
    }

    get minYear(): number
    {
        return moment().subtract(100, 'years').year();
    }

    get date(): Moment
    {
        if (!this.day.valid || !this.month.valid || !this.year.valid) {
            return moment([-1]);
        }

        return moment([this.year.value, this.month.value, this.day.value], 'YYYY-MM-DD');
    }

    get dateValid(): boolean
    {
        return this.day.valid && this.month.valid && this.year.valid && this.date.isValid();
    }

    get valid(): boolean
    {
        return this.birthdateForm != null && this.dateValid;
    }

    get step(): ProfilePersonalizeSteps
    {
        return ProfilePersonalizeSteps.birthdate;
    }

    get working(): boolean
    {
        return this.birthdateForm != null && this.birthdateForm.disabled;
    }

    override ngOnInit(): void
    {
        super.ngOnInit();

        let dateOfBirth: Nullable<moment.Moment>;

        if (this.profile.dateOfBirth) {
            dateOfBirth = moment(this.profile.dateOfBirth);
        }

        const dayFormControl = new FormControl(dateOfBirth ? dateOfBirth.toDate().getDate() : null, [
            Validators.min(1),
            Validators.max(31),
        ]);
        dayFormControl.valueChanges.subscribe(this.valueChanged.bind(this));

        const monthFormControl = new FormControl(dateOfBirth ? dateOfBirth.toDate().getMonth() + 1 : null, [
            Validators.min(1),
            Validators.max(12),
        ]);
        monthFormControl.valueChanges.subscribe(this.valueChanged.bind(this));

        const yearFormControl = new FormControl(dateOfBirth ? dateOfBirth.toDate().getFullYear() : null, [
            Validators.min(this.minYear),
            Validators.max(this.maxYear),
        ]);
        yearFormControl.valueChanges.subscribe(this.valueChanged.bind(this));

        this.birthdateForm = new FormGroup({
            day  : dayFormControl,
            month: monthFormControl,
            year : yearFormControl,
        });

        this._personalizeService.personalizeHost = this;
    }

    ngOnDestroy(): void
    {
        this._personalizeService.personalizeHost = null;
    }

    async next(): Promise<boolean>
    {
        return this.saveProfile();
    }

    override async saveProfile(): Promise<boolean>
    {
        this.birthdateForm.disable();
        try {
            const result = await super.saveProfile();

            if (result) {
                this.day.setValue(this.profile.dateOfBirth.getDate());
                this.month.setValue(this.profile.dateOfBirth.getMonth() + 1);
                this.year.setValue(this.profile.dateOfBirth.getFullYear());
            }

            return result;
        }
        catch (error) {
            console.error(error);
            return false;
        }
        finally {
            this.birthdateForm.enable();
        }
    }

    public inputKeyUp(currentInput: HTMLInputElement, nextInput: HTMLInputElement): void
    {
        if (currentInput.value.length === 2) {
            nextInput.focus();
            nextInput.select();
        }
    }

    protected valueChanged(): void
    {
        if (!this.dateValid) {
            return;
        }
        this.profile.dateOfBirth = this.date.toDate();
    }
}
