<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
@if (layout === 'empty') {
  <empty-layout></empty-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Centered -->
@if (layout === 'centered') {
  <centered-layout></centered-layout>
}

<!-- Enterprise -->
@if (layout === 'enterprise') {
  <enterprise-layout></enterprise-layout>
}

<!-- Material -->
@if (layout === 'material') {
  <material-layout></material-layout>
}

<!-- Modern -->
@if (layout === 'modern') {
  <modern-layout></modern-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Classic -->
@if (layout === 'classic') {
  <classic-layout></classic-layout>
}

<!-- Classy -->
@if (layout === 'classy') {
  <classy-layout></classy-layout>
}

<!-- Compact -->
@if (layout === 'compact') {
  <compact-layout></compact-layout>
}

<!-- Dense -->
@if (layout === 'dense') {
  <dense-layout></dense-layout>
}

<!-- Futuristic -->
@if (layout === 'futuristic') {
  <futuristic-layout></futuristic-layout>
}

<!-- Thin -->
@if (layout === 'thin') {
  <thin-layout></thin-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<settings></settings>

@if (displayErrorAlerts) {
  <yukawa-console-log-alerts></yukawa-console-log-alerts>
}

<!-- Alert -->
<yukawa-alert
  #alert
  name="global"
  class="absolute block ml-auto mr-auto left-15 lg:left-0 top-10 right-15 lg:right-0 w-auto lg:w-200 h-auto"
  [ngClass]="{ 'min-h-15': !alert.dismissed }"
  style="z-index: 99999"
  appearance="outline"
  showIcon="true"
  dismissible="true"
  dismissed="true">
</yukawa-alert>
