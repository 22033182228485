import { Observable, merge, BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';

//import { ErrorDialog } from '../error/error-dialog.component';
const TAG$4 = 'TableDataSource';
class TableDataSource extends DataSource {
    //errorSubject: BehaviorSubject<Error> = new BehaviorSubject<Error>();
    status;
    //statusEvent = new EventEmitter<string>();
    errorEvent = new EventEmitter();
    showSpinner = true;
    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    disconnect() {
        if (this.errorEvent)
            this.errorEvent.complete();
    }
    /**
     * overload to provide post load filtering
     */
    // noinspection JSMethodCanBeStatic
    filterData(data) {
        return data;
    }
    // noinspection JSMethodCanBeStatic
    applyPaginator(filter, paginator) {
        if (filter && paginator) {
            filter.pager = {
                firstResult: (paginator.pageIndex || 0) * paginator.pageSize,
                pageSize: paginator.pageSize
            };
        }
    }
    // noinspection JSMethodCanBeStatic
    applySort(filter, sort) {
        if (filter) {
            if (sort && sort.active) {
                filter.orderDir = sort.direction ? sort.direction.toUpperCase() : "ASC";
                filter.orderBy = sort.active;
            }
            if (filter.orderBy) {
                filter.orderBy = filter.orderBy.trim();
                if (filter.orderBy == '')
                    delete filter.orderBy;
            }
        }
    }
    // noinspection JSMethodCanBeStatic
    applyKeyword(filter, value) {
        if (filter && value) {
            filter.keyword = value;
        }
    }
    onError(e, op, component = this.constructor.name) {
        this.status = 'error';
        e.error = Object.assign({ operation: op, component: component }, e.error);
        console.warn(TAG$4, 'Error loading data', op, e);
        this.errorEvent.emit(e);
    }
}

//import {FilteredTableSource} from "./filtered-table-source";
//import {finalize} from "rxjs/operators";
const TAG$3 = 'MatTableSource';
class MatTableSource extends TableDataSource {
    paginator;
    sort;
    dataList = [];
    constructor(paginator, sort) {
        super();
        this.paginator = paginator;
        this.sort = sort;
        this.status = 'loading';
    }
    /**
     * @return data mutations which trigger table reload
     */
    initReloadMutations() {
        const dataMutations = [];
        if (this.paginator)
            dataMutations.push(this.paginator.page);
        if (this.sort)
            dataMutations.push(this.sort.sortChange);
        return dataMutations;
    }
    /**
     * @param totalRows total number of rows (without paging)
     */
    updatePager(totalRows) {
        if (this.paginator) {
            this.paginator.length = totalRows;
        }
    }
    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    connect() {
        // Combine everything that affects the rendered data into one update
        // stream for the data-table to consume.
        const dataMutations = this.initReloadMutations();
        return new Observable(observer => {
            merge(...dataMutations).subscribe(() => {
                this.reloadTable().subscribe(rows => {
                    if (this.dataList.length == 0)
                        this.dataList = rows;
                    observer.next(this.filterData(rows));
                });
            });
        });
    }
    pageData(data) {
        if (this.paginator != null) {
            this.paginator.length = data.length;
            const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
            data = data.splice(startIndex, this.paginator.pageSize);
        }
        return data;
    }
    sortData(data) {
        if (!this.sort || !this.sort.active || this.sort.direction == '') {
            return data;
        }
        return data.sort((a, b) => {
            let propertyA;
            let propertyB;
            let prop = this.sort.active;
            //[propertyA, propertyB] = [a[prop], b[prop]];
            [propertyA, propertyB] = this.sortValues(a, b, prop);
            let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            let valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            return (valueA < valueB ? -1 : 1) * (this.sort.direction == 'asc' ? 1 : -1);
        });
    }
    sortValues(a, b, prop) {
        return [a[prop], b[prop]];
    }
    searchData(data) {
        return data;
    }
}
class MixMatTableSource extends MatTableSource {
    paginator;
    sort;
    dataSubject;
    serverMutations;
    autoLoad = true;
    constructor(paginator, sort) {
        super(paginator, sort);
        this.paginator = paginator;
        this.sort = sort;
        this.dataSubject = new BehaviorSubject(null);
    }
    connect() {
        this.serverMutations = this.initReloadMutations();
        if (this.serverMutations.length > 0) {
            merge(...this.serverMutations).subscribe(() => this.reload());
        }
        else {
            console.debug(TAG$3, "connect: autoLoad", this.autoLoad);
            if (this.autoLoad) {
                this.reload();
            }
        }
        const dataMutations = this.initDisplayMutations();
        return new Observable(observer => {
            merge(...dataMutations).subscribe(() => {
                if (this.dataSubject.value)
                    observer.next(this.filterData(this.dataSubject.value.slice()));
            });
        });
    }
    disconnect() {
        if (this.dataSubject != null)
            this.dataSubject.complete();
        super.disconnect();
    }
    filterData(data) {
        const origLength = data.length;
        const serverMutations = this.serverMutations;
        // if(this.filterSubject && serverMutations.indexOf(this.filterSubject) == -1)
        //   data = this.searchData(data);
        if (this.sort && serverMutations.indexOf(this.sort.sortChange) == -1)
            data = this.sortData(data);
        if (this.paginator && serverMutations.indexOf(this.paginator.page) == -1)
            data = this.pageData(data);
        console.debug(TAG$3, "filterData:", data.length, "/", origLength);
        return data;
    }
    reload() {
        console.debug(TAG$3, "reload");
        this.status = 'loading';
        this.reloadTable()
            //.pipe(finalize(() => this.status = 'ready'))
            .subscribe({
            next: rows => this.onLoaded(rows),
            error: error => this.onError(error, 'reload')
        });
    }
    onLoaded(rows) {
        this.status = 'ready';
        this.dataSubject.next(rows);
    }
    /**
     * overload to setup server side mutations.
     */
    initReloadMutations() {
        return this.findAllMutations();
    }
    findAllMutations() {
        return super.initReloadMutations();
    }
    initClientMutations() {
        // remove server side mutations
        const remove = this.serverMutations;
        const dataMutations = this.findAllMutations();
        return dataMutations.filter(function (item) {
            return remove.indexOf(item) === -1;
        });
    }
    initDisplayMutations() {
        let dataMutations = [this.dataSubject];
        dataMutations = dataMutations.concat(this.initClientMutations());
        return dataMutations;
    }
    // protected applyFilter(filter : F) {
    //   if(this.paginator && this.serverMutations.indexOf(this.paginator.page) != -1)
    //     this.applyPaginator(filter, this.paginator);
    //   if(this.sort && this.serverMutations.indexOf(this.sort.sortChange) != -1)
    //     this.applySort(filter, this.sort);
    //   this.applyKeyword(filter);
    // }
    applyPaginator(filter, paginator) {
        if (paginator && this.serverMutations.indexOf(paginator.page) != -1)
            super.applyPaginator(filter, paginator);
    }
    applySort(filter, sort) {
        if (sort && this.serverMutations.indexOf(sort.sortChange) != -1)
            super.applySort(filter, sort);
    }
    setPaginator(paginator) {
        this.paginator = paginator;
    }
    setSort(sort) {
        this.sort = sort;
    }
}

const TAG$2 = 'FilteredTableSource';
class FilteredTableSource extends MixMatTableSource {
    paginator;
    sort;
    tableFilter;
    filterSubject;
    constructor(paginator, sort, tableFilter) {
        super(paginator, sort);
        this.paginator = paginator;
        this.sort = sort;
        this.tableFilter = tableFilter;
        this.filterSubject = new BehaviorSubject(tableFilter);
    }
    disconnect() {
        if (this.filterSubject != null)
            this.filterSubject.complete();
        super.disconnect();
    }
    applyFilter(filter) {
        this.applyPaginator(filter, this.paginator);
        this.applySort(filter, this.sort);
        this.applyKeyword(filter);
    }
    mergeApplyFilter(filter) {
        this.filterSubject.next({ ...this.filterSubject.value, ...filter });
    }
    findAllMutations() {
        let mutations = super.findAllMutations();
        if (this.filterSubject)
            mutations.push(this.filterSubject);
        //console.debug(TAG, "findAllMutations: mutations", mutations.length);
        return mutations;
    }
    findFilter() {
        let filter = this.filterSubject.value;
        if (!filter) {
            filter = {};
        }
        return filter;
    }
    filterData(data) {
        const serverMutations = this.serverMutations;
        //console.debug(TAG, "filterData: serverMutations", serverMutations);
        if (this.filterSubject && serverMutations.indexOf(this.filterSubject) == -1)
            data = this.searchData(data);
        return super.filterData(data);
    }
}

//import {MatTableSource} from "./mat-table-source";
//import {MatPaginator} from "@angular/material/paginator";
//import {MatSort} from "@angular/material/sort";
const TAG$1 = 'QueryTableSource';
/**
 * Data source for QueryResult objects
 * (including sorting, pagination, and filtering).
 */
class QueryTableSource extends FilteredTableSource {
    reloadTable() {
        return this.queryTable().pipe(map(qr => {
            this.updatePager(qr.rows);
            return qr.items;
        }));
    }
}

const TAG = 'SearchTableSource';
/**
 * Data source for SearchResult objects
 * (including sorting, pagination, and filtering).
 */
class SearchTableSource extends FilteredTableSource {
    reloadTable() {
        return this.searchTable().pipe(map(qr => {
            this.updatePager(qr.total);
            return qr.rows;
        }));
    }
}

/*
 * Public API Surface of yukawa-lib-mat
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FilteredTableSource, MatTableSource, MixMatTableSource, QueryTableSource, SearchTableSource, TableDataSource };

