<div class="flex flex-col w-full h-full">
  <h2>
    {{ heading }}

    @for (_subHeading of subHeadings; track _subHeading; let i = $index) {
      @if (subHeadingIndex === i) {
        <span @fadeIn class="text-red-400"
        >{{ _subHeading }}</span>
      }
    }

  </h2>

  <div class="relative w-full h-full">
    <ng-lottie [options]="options" class="fixed left-0 top-0 right-0 bottom-0"></ng-lottie>
  </div>

</div>
