import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, enableProdMode, NgModule } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as i1 from '@angular/router';
import { NavigationEnd, Router, NavigationCancel, NavigationError } from '@angular/router';
import { TemplateString, ENVIRONMENT_TOKEN, LogService } from '@yukawa/chain-base-angular-client';
import { Subject, takeUntil, interval, filter, take, first } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { debounceTime } from 'rxjs/operators';

// eslint-disable-next-line prefer-const
let SPLASH_SCREEN_TEMPLATE = TemplateString`<div id="app-logo"></div>

<svg id="spinners" class="hidden"></svg>
<div id="spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
</div>

<div id="app-log"></div>

<div id="actions">
    <div class="h-20 grid grid-cols-3 gap-4 place-content-around">
        <button class="w-20 flex flex-col gap-2" onclick="window.splashScreen.reload()">
            <svg viewBox="0 0 40 40" width="40" height="40">
                <use xlink:href="#debug-rerun"></use>
            </svg>
            <span class="label">${'reload'}</span>
        </button>
        <div class="place-self-center flex flex-col gap-2" title="${'download'}">
            <svg viewBox="0 0 40 40" width="40" height="40">
                <use xlink:href="#download-bracket"></use>
            </svg>
            <div class="flex gap-4">
                <button class="mx-auto" onclick="window.logService.save('text')">
                    <span class="label">${'downloadText'}</span>
                </button>
                <button class="mx-auto" onclick="window.logService.save('json')">
                    <span class="label">${'downloadJson'}</span>
                </button>
            </div>
        </div>
        <button class="w-20 flex flex-col gap-2" onclick="window.dispatchEvent(new Event('close-splash-screen'))">
            <svg viewBox="0 0 40 40" width="40" height="40">
                <use xlink:href="#debug-alt-small"></use>
            </svg>
            <span class="label">${'close'}</span>
        </button>
    </div>
</div>`;
const dots = ['scale-middle', 'bounce', 'move', 'fade', 'rotate', 'scale'];
// eslint-disable-next-line prefer-const
let SPLASH_SCREEN_LOG_TEMPLATE = TemplateString`<h1>${'appLog'}</h1>
<div id="log-table-container" class="mt-4 overflow-y-scroll">
    <table class="h-full w-full relative">
        <thead class="sticky top-0">
            <tr>
                <td>${'type'}</td>
                <td>${'message'}</td>
            </tr>
        </thead>
        <tbody>${'logs'}</tbody>
    </table>
</div>`;
class YukawaSplashScreen extends HTMLElement {
  #initialized = false;
  #toggleDotsSubscription;
  #unsubscribeAll = new Subject();
  #showLog = new Subject();
  constructor() {
    super();
  }
  get logs() {
    return window.logService.logs.map(log => `<tr class="${log.type}"><td>${log.type}</td><td>${log.message}</td></tr>`).join('\n');
  }
  set listenToLog(value) {
    if (value) {
      window.logService.logged.subscribe(this.logged, this);
      this.#showLog.next(null);
    } else {
      window.logService.logged.unsubscribe(this.logged);
    }
  }
  async connectedCallback() {
    if (this.isConnected) {
      window.splashScreen = this;
      this.#showLog.pipe(takeUntil(this.#unsubscribeAll), debounceTime(500)).subscribe(this.showLog.bind(this));
      await this.init();
    }
  }
  disconnectedCallback() {
    this.#showLog.complete();
    this.listenToLog = false;
    this.#unsubscribeAll.next(null);
    this.#unsubscribeAll.complete();
    delete window.splashScreen;
  }
  appLoaded = () => {
    if (!YukawaSplashScreenService.logsAvailable) {
      return;
    }
    this.listenToLog = true;
    window.addEventListener('close-splash-screen', () => {
      window.logService.logged.unsubscribe(this.logged);
    });
    document.removeEventListener('app-loaded', this.appLoaded);
  };
  showLog() {
    const log = this.getElement('#app-log');
    const actions = this.getElement('#actions');
    const spinner = this.getElement('#spinner');
    this.#toggleDotsSubscription?.unsubscribe();
    spinner.classList.add('hide');
    const setLogHeight = () => {
      // get log table container height
      const logTableContainer = document.getElementById('log-table-container');
      let extendedHeight = Array.from(logTableContainer.children).reduce((current, next) => current + next.offsetHeight, 0);
      // add sticky table header height
      const item = log.getElementsByTagName('thead').item(0);
      extendedHeight += item?.offsetHeight ?? 0;
      // Set initial log container height
      log.style.setProperty('--extended-height', extendedHeight.toString() + 'px');
    };
    if (!this.#initialized) {
      spinner.classList.add('hidden');
      log.innerHTML = SPLASH_SCREEN_LOG_TEMPLATE({
        appLog: 'App Log',
        type: 'Type',
        message: 'Message',
        logs: this.logs
      });
      log.classList.add('show');
      setLogHeight();
      // Display action buttons
      actions.classList.add('show');
      const tbody = log.getElementsByTagName('tbody').item(0);
      if (!tbody) {
        return;
      }
      const scrollTo = logType => {
        const warnEntries = tbody.getElementsByClassName(logType);
        if (warnEntries.length > 0) {
          warnEntries.item(0).scrollIntoView({
            block: 'nearest',
            behavior: 'smooth'
          });
          return true;
        }
        return false;
      };
      for (const _logType of ENVIRONMENT_TOKEN.value.logTypes) {
        if (scrollTo(_logType)) {
          break;
        }
      }
      this.#initialized = true;
    } else {
      const loggedEventArg = window.logService.logs[window.logService.logs.length - 1];
      if (loggedEventArg.message.indexOf('[webpack-dev-server]') > -1) {
        this.displayDots(log, actions, spinner);
      } else {
        log.classList.remove('hide');
        actions.classList.remove('hide');
        spinner.classList.add('hide', 'hidden');
      }
      const tbody = log.getElementsByTagName('tbody').item(0);
      if (!tbody) {
        return;
      }
      tbody.innerHTML = this.logs;
      setLogHeight();
      tbody.scrollIntoView({
        block: 'end',
        behavior: 'smooth'
      });
    }
  }
  toggleDots(toggle = true) {
    if (!toggle) {
      this.#toggleDotsSubscription?.unsubscribe();
      return;
    }
    if (this.#toggleDotsSubscription?.closed === false) {
      return;
    }
    const use = this.getElement('#spinner use');
    let index = 0;
    use.setAttribute('xlink:href', '#3-dots-' + dots[index]);
    this.#toggleDotsSubscription = interval(1500).pipe(takeUntil(this.#unsubscribeAll)).subscribe(() => {
      use.setAttribute('xlink:href', '#3-dots-' + dots[++index]);
      if (index === dots.length - 1) {
        index = 0;
      }
    });
  }
  reload() {
    this.displayDots();
    setTimeout(() => {
      document.location.reload();
    }, 1000);
  }
  async init() {
    document.addEventListener('app-loaded', this.appLoaded);
    this.innerHTML = SPLASH_SCREEN_TEMPLATE({
      download: 'Download App Log',
      downloadText: 'Text',
      downloadJson: 'JSON',
      reload: 'Reload',
      close: 'Continue'
    });
    const svg = (await fetch('/assets/icons/yukawa/splash-screen.svg')).text();
    const spinner = this.getElement('#spinner');
    const spinners = this.getElement('#spinners');
    spinner.outerHTML = `<svg id="spinner" viewBox="0 0 56 56">
    <use xlink:href="#scale"></use>
</svg>`;
    spinners.outerHTML = await svg;
    this.toggleDots();
  }
  getElement(selector) {
    const spinner = this.querySelector(selector);
    if (!spinner) {
      throw new Error(`Splash screen element ${selector} not found.`);
    }
    return spinner;
  }
  displayDots(log = this.getElement('#app-log'), actions = this.getElement('#actions'), spinner = this.getElement('#spinner')) {
    log.classList.add('hide');
    actions.classList.add('hide');
    spinner.classList.remove('hide', 'hidden');
    this.toggleDots();
  }
  logged(sender, ea) {
    this.#showLog.next(null);
  }
}
const YUKAWA_SPLASH_SCREEN_NAME = 'yukawa-splash-screen';
customElements.define('yukawa-splash-screen', YukawaSplashScreen);
const SPLASHSCREEN_ANIMATED_TOKEN = new InjectionToken('SPLASHSCREEN_ANIMATED_TOKEN');
class YukawaSplashScreenService {
  _document;
  _injector;
  _router;
  _ngZone;
  hideTimeout;
  /**
   * Constructor
   */
  constructor(_document, _injector, _router, _ngZone) {
    this._document = _document;
    this._injector = _injector;
    this._router = _router;
    this._ngZone = _ngZone;
    // Hide it on the first NavigationEnd event
    this._router.events.pipe(filter(event => event instanceof NavigationEnd), take(1)).subscribe(routerEvent => {
      if (YukawaSplashScreenService.logsAvailable) {
        window.addEventListener('close-splash-screen', this.closSplashScreen);
      }
    });
    window.logService.logged.subscribe(this.logged, this);
  }
  static get logsAvailable() {
    return window.logService.logsAvailable(...ENVIRONMENT_TOKEN.value.logTypes);
  }
  get hideClassName() {
    return this._injector.get(SPLASHSCREEN_ANIMATED_TOKEN) ? 'yukawa-splash-screen-hidden-animated' : 'yukawa-splash-screen-hidden';
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Show the splash screen
   */
  show() {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = null;
    }
    window.splashScreen.classList.remove('hidden');
    this._document.body.classList.remove(this.hideClassName);
    if (this._document.getElementsByTagName(YUKAWA_SPLASH_SCREEN_NAME).length === 0) {
      const splashScreen = this._document.createElement(YUKAWA_SPLASH_SCREEN_NAME);
      this._document.body.prepend(splashScreen);
    } else {
      window.splashScreen.toggleDots(true);
      window.splashScreen.listenToLog = true;
      window.splashScreen.appLoaded();
    }
  }
  /**
   * Hide the splash screen
   */
  hide(force = false) {
    if (force || !YukawaSplashScreenService.logsAvailable) {
      window.removeEventListener('close-splash-screen', this.closSplashScreen);
      window.dispatchEvent(new Event('close-splash-screen'));
      this._document.body.classList.add(this.hideClassName);
      this.hideTimeout = setTimeout(() => {
        window.splashScreen?.toggleDots(false);
        window.splashScreen.classList.add('hidden');
      }, 1000);
    }
  }
  closSplashScreen = () => {
    this.hide(true);
  };
  logged(sender, ea) {
    if (ea.message.indexOf('[webpack-dev-server]') > -1) {
      if (ea.message.indexOf('App updated. Recompiling...') > -1) {
        this.show();
      } else if (ea.message.indexOf('Nothing changed.') > -1) {
        this.hide(true);
        window.logService.logged.unsubscribe(this.logged);
        this._ngZone.run(() => {
          console.warn(ea.message);
        });
        window.logService.logged.subscribe(this.logged, this);
      }
    }
  }
  /** @nocollapse */
  static ɵfac = function YukawaSplashScreenService_Factory(t) {
    return new (t || YukawaSplashScreenService)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: YukawaSplashScreenService,
    factory: YukawaSplashScreenService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(YukawaSplashScreenService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Injector
  }, {
    type: i1.Router
  }, {
    type: i0.NgZone
  }], null);
})();
const logService = LogService.instance;
const run = (module, production) => {
  if (production) {
    enableProdMode();
  }
  const appLoaded = () => {
    logService.logged.unsubscribe(logged);
    document.dispatchEvent(new Event('app-loaded'));
  };
  const logged = (sender, ea) => {
    if (YukawaSplashScreenService.logsAvailable) {
      appLoaded();
    }
  };
  logService.logged.subscribe(logged);
  platformBrowserDynamic().bootstrapModule(module).then(_module => {
    _module.injector.get(Router).events.pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError), first()).subscribe(event => {
      setTimeout(() => {
        appLoaded();
      });
    });
  }).catch(error => {
    console.error(error);
    appLoaded();
  });
};
class YukawaSplashScreenModule {
  static forRoot(animated) {
    return {
      ngModule: YukawaSplashScreenModule,
      providers: [{
        provide: SPLASHSCREEN_ANIMATED_TOKEN,
        useValue: animated
      }]
    };
  }
  /** @nocollapse */
  static ɵfac = function YukawaSplashScreenModule_Factory(t) {
    return new (t || YukawaSplashScreenModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: YukawaSplashScreenModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(YukawaSplashScreenModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SPLASHSCREEN_ANIMATED_TOKEN, SPLASH_SCREEN_LOG_TEMPLATE, SPLASH_SCREEN_TEMPLATE, YUKAWA_SPLASH_SCREEN_NAME, YukawaSplashScreenModule, YukawaSplashScreenService, run };
