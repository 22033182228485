import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { Gender } from '@swan/lib/domain';
import { ProfilePersonalizeSteps, ProfileService } from '@swan/lib/profile';
import { IPersonalizeHost, PersonalizeService } from '../personalize.service';
import { ProfileComponentBase } from '../profile-component-base';


@Component({
    selector   : 'lib-gender',
    templateUrl: './gender.component.html',
    styleUrls  : ['./gender.component.scss'],
})
export class GenderComponent extends ProfileComponentBase implements OnInit, OnDestroy, IPersonalizeHost
{
    disabled: boolean    = false;
    private _genderArray = new Array<Gender>();

    constructor(
        _profileService: ProfileService,
        private _personalizeService: PersonalizeService,
    )
    {
        super(_profileService);
    }

    public get gender(): Gender
    {
        return this.profile.gender;
    }

    get valid(): boolean
    {
        return this.profile.gender != null;
    }

    get step(): ProfilePersonalizeSteps
    {
        return ProfilePersonalizeSteps.gender;
    }

    get working(): boolean
    {
        return this.disabled;
    }

    override ngOnInit(): void
    {
        super.ngOnInit();
        this._genderArray.push(this.profile.gender);
        this._personalizeService.personalizeHost = this;
    }

    public ngOnDestroy(): void
    {
        this._personalizeService.personalizeHost = null;
    }

    public async genderChanged(event: MatSelectionListChange): Promise<void>
    {
        if (!event.options[0].selected) {
            event.options[0].selected = true;
            return;
        }
        this.profile.gender = event.options[0].value;
    }

    public async next(): Promise<boolean>
    {
        return this.saveProfile();
    }

    override async saveProfile(): Promise<boolean>
    {
        this.disabled = true;
        try {
            const result = await super.saveProfile();
            if (result) {
                this._genderArray.length = 0;
                this._genderArray.push(this.profile.gender);
            }
            return result;
        }
        catch (error) {
            console.error(error);
            return false;
        }
        finally {
            this.disabled = false;
        }
    }
}
