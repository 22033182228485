<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
@if (isScreenSmall) {
  <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
    [navigation]="navigation.default" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-6 px-8">
        <mat-icon [svgIcon]="'swan:icon'" class="icon-size-10"></mat-icon>
      </div>
    </ng-container>
  </fuse-vertical-navigation>
}

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    @if (!isScreenSmall) {
      <!-- Logo -->
      <div class="flex items-center mx-2 lg:mr-8">
        <div class="hidden lg:flex">
          <!-- Light version -->
          <mat-icon [svgIcon]="'swan:icon'" class="dark:hidden icon-size-10"></mat-icon>
          <!-- Dark version -->
          <mat-icon [svgIcon]="'swan:icon'" class="hidden dark:flex icon-size-10"></mat-icon>
        </div>
        <mat-icon [svgIcon]="'swan:icon'" class="flex lg:hidden w-8"></mat-icon>
      </div>
      <!-- Horizontal navigation -->
      <fuse-horizontal-navigation class="mr-2" [name]="'mainNavigation'"
      [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
    }
    <!-- Navigation toggle button -->
    @if (isScreenSmall) {
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
    }
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <languages></languages>
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <!-- <shortcuts></shortcuts> -->

      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
    Otherwise, layout changes won't be registered and the view won't be updated! -->
    @if (true) {
      <router-outlet></router-outlet>
    }
  </div>

  <!-- Footer -->
  <div
    class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
    <span class="font-medium text-secondary">Swan &copy; {{currentYear}}</span>
  </div>

</div>