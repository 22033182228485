import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { fadeIn, fadeOut } from '@swan/lib/animations';
import { Profile, ProfileService } from '@swan/lib/profile';
import { filter, Subject, takeUntil } from 'rxjs';
import { UserIconComponent } from '../user-icon/user-icon.component';


@Component({
    selector   : 'lib-profile-icon',
    templateUrl: './profile-icon.component.html',
    styleUrls  : ['./profile-icon.component.scss'],
    animations : [fadeIn, fadeOut],
})
export class ProfileIconComponent implements OnInit, OnDestroy
{
    @ViewChild('userIcon', { static: false })
    userIconComponent!: UserIconComponent;

    @Input()
    size: number = 48;

    @Input()
    percentage!: number;

    @Input()
    profile!: Profile;

    readonly #unsubscribeAll = new Subject<void>();

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private _router: Router,
        private _profileService: ProfileService,
    )
    {
    }

    public get profileImageAvailable(): boolean
    {
        return this.profile.imageUrl != null;
    }

    public async ngOnInit(): Promise<void>
    {
        await this.reload();

        const url = this._router.url;
        this._router.events
            .pipe(
                filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                takeUntil(this.#unsubscribeAll),
            )
            .subscribe(async (event: NavigationEnd) =>
            {
                if (event.urlAfterRedirects === url) {
                    await this.reload();
                }
            });
    }

    public ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.#unsubscribeAll.next();
        this.#unsubscribeAll.complete();
    }

    async reload(): Promise<void>
    {
        if (!this.profile || this._profileService.profile !== this.profile) {
            this.profile = this._profileService.profile as Profile;
            if (this._profileService.profile?.isAdmin) {
                await this._profileService.loadImage(this.profile);
            }
        }
        else {
            this._changeDetector.detectChanges();
            await this.userIconComponent?.ngOnInit();
        }

        if (this.profile) {
            this.percentage = this.profile.personalized;
        }
    }
}
