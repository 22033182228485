import {ChainEventBean, Change, ChangeFilter, Changed, Created, DataRange, EntityFilter, Info, InstantRange} from '@yukawa/chain-base-angular-domain';
import {Product, ProductStatus, ProductVariant, SwanStatus} from './marketplace';
import {InterestInfo, ProfileFilter, PublicProfile} from './profile';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.Comment <br/>
*/
export interface Comment extends  Changed, Created{
author?: PublicProfile;
change?: Change;
children?: Comment[];
commentId?: number;
contentId?: number;
created?: Change;
liked?: boolean;
likedCount?: number;
mentions?: string[];
parentCommentId?: number;
setLiked?: undefined | boolean;
text?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.CommentFilter <br/>
*/
export interface CommentFilter extends  EntityFilter{
authorFilter?: ProfileFilter;
changeFilter?: ChangeFilter;
commentId?: number;
commentIds?: number[];
contentId?: number;
createdFilter?: ChangeFilter;
enrichChildren?: boolean;
excludedAuthorFilter?: ProfileFilter;
mentionKeyword?: string;
parentCommentId?: number;
parentCommentIds?: number[];
withParent?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.Content <br/>
*/
export interface Content extends  Changed, Created{
author?: PublicProfile;
change?: Change;
contentId?: number;
created?: Change;
duration?: number;
extAssetId?: string;
extPlaybackId?: string;
fileName?: string;
fileSize?: number;
info?: Info;
interests?: ContentInterest[];
language?: string;
liveStream?: LiveStream;
masterclass?: Masterclass;
mime?: string;

/**
*	@example mux
*/
provider?: string;
recordingDate?: Date;
splashScreen?: string;
stats?: ContentStatistics;
status?: ContentStatus;
thumbnail?: string;
trendingScore?: number;

/**
*	@example video
*/
type?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentEvent <br/>
*/
export interface ContentEvent extends  ChainEventBean<Content> {
comment?: Comment;
sourceUser?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentEventFilter <br/>
*/
export interface ContentEventFilter extends  ContentFilter{
eventName?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentFilter <br/>
*/
export interface ContentFilter extends  EntityFilter{
authorFilter?: ProfileFilter;
contentId?: number;
contentIds?: number[];
contentInterestFilter?: ContentInterestFilter;
duration?: DataRange<number> ;
excludedAuthorFilter?: ProfileFilter;
excludedContentId?: number;
excludedContentIds?: number[];
extAssetId?: string;
fileName?: string;
hasProduct?: boolean;
info?: Info;
interestIdsInsteadKeyword?: string[];
languages?: string[];
liveStream?: LiveStreamFilter;
masterclassFilter?: MasterclassFilter;
statsFilter?: ContentStatisticsFilter;
status?: ContentStatus;
statuses?: ContentStatus[];
stream?: ContentStreamType;
types?: string[];
username?: string;
withoutBlockedByMe?: boolean;
withoutBlockedByUsername?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentInterest <br/>
*/
export interface ContentInterest {
contentId?: number;
interestId?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentInterestFilter <br/>
*/
export interface ContentInterestFilter extends  EntityFilter{
contentId?: number;
contentIds?: number[];
excludedContentIds?: number[];
excludedInterestIds?: string[];
interestId?: string;
interestIds?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentProduct <br/>
*/
export interface ContentProduct {
content?: Content;
contentId?: number;
productVariant?: ProductVariant;
productVariantId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentProductDistinct <br/>
*/
export interface ContentProductDistinct {
content?: Content;
contentId?: number;
product?: Product;
productId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentProductFilter <br/>
*/
export interface ContentProductFilter extends  EntityFilter{
contentId?: number;

/**
*	@example ["video"]
*/
contentTypes?: string[];
loadContent?: boolean;
loadProductVariant?: boolean;
productId?: number;
productStatuses?: ProductStatus[];
productVariantId?: number;
swanStatuses?: SwanStatus[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentStatistics <br/>
*/
export interface ContentStatistics {
contentId?: number;
favorites?: number;
likes?: number;
ticks?: number;
trendWeekly?: number;
trendWeeklyChange?: string;
viewed?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentStatisticsFilter <br/>
*/
export interface ContentStatisticsFilter extends  EntityFilter{
contentId?: number;
likesRange?: DataRange<number> ;
ticksRange?: DataRange<number> ;
trendWeeklyChangeRange?: DataRange<string> ;
trendWeeklyRange?: DataRange<number> ;
viewedRange?: DataRange<number> ;
}

export enum ContentStatus {
NEW = "NEW",
UPLOADING = "UPLOADING",
UPLOADED = "UPLOADED",
PUBLISHED = "PUBLISHED"
}

export enum ContentStreamType {
TRENDING = "TRENDING",
FAVORITE = "FAVORITE",
RECOMMENDED = "RECOMMENDED",
VIEWED = "VIEWED",
RELATED = "RELATED",
FOLLOWED = "FOLLOWED",
NEWS = "NEWS",
SHORTS = "SHORTS",
POPULAR = "POPULAR",
FOR_YOU = "FOR_YOU"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.ContentView <br/>
*/
export interface ContentView {
content?: Content;
favorite?: boolean;
liked?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.FavoriteContent <br/>
*/
export interface FavoriteContent extends  Changed{
change?: Change;
contentId?: number;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.FavoriteContentFilter <br/>
*/
export interface FavoriteContentFilter extends  EntityFilter{
changeFilter?: ChangeFilter;
contentIds?: number[];
username?: string;
usernames?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.LikedCommentFilter <br/>
*/
export interface LikedCommentFilter extends  EntityFilter{
commentIds?: number[];
username?: string;
usernames?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.LikedCommentPK <br/>
*/
export interface LikedCommentPK {
commentId?: number;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.LikedContent <br/>
*/
export interface LikedContent extends  Changed{
change?: Change;
contentId?: number;
profile?: PublicProfile;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.LikedContentComment <br/>
*/
export interface LikedContentComment extends  Changed{
change?: Change;
commentId?: number;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.LikedContentFilter <br/>
*/
export interface LikedContentFilter extends  EntityFilter{
changeFilter?: ChangeFilter;
contentIds?: number[];
username?: string;
usernames?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.LiveStream <br/>
*/
export interface LiveStream extends  Changed, Created{
change?: Change;
contentId?: number;
created?: Change;
endTime?: string;
extStreamId?: string;
scheduledTime?: string;
startTime?: string;
status?: StreamStatus;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.LiveStreamFilter <br/>
*/
export interface LiveStreamFilter extends  EntityFilter{
contentId?: number;
contentIds?: number[];
extStreamId?: string;
scheduledTime?: InstantRange;
status?: StreamStatus;
statuses?: StreamStatus[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.Masterclass <br/>
*/
export interface Masterclass extends  Changed, Created{
change?: Change;
contentId?: number;
created?: Change;
duration?: number;
extAssetId?: string;
extPlaybackId?: string;
fileName?: string;
fileSize?: number;
price?: number;
status?: ContentStatus;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.MasterclassFilter <br/>
*/
export interface MasterclassFilter extends  EntityFilter{
contentId?: number;
contentIds?: number[];
duration?: DataRange<number> ;
excludedContentIds?: number[];
extAssetId?: string;
fileName?: string;
masterclassPaymentFilter?: MasterclassPaymentFilter;
status?: ContentStatus;
statuses?: ContentStatus[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.MasterclassInterest <br/>
*/
export interface MasterclassInterest {
count?: number;
id?: string;
name?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.MasterclassInterestFilter <br/>
*/
export interface MasterclassInterestFilter extends  EntityFilter{
id?: string;
name?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.MasterclassPayment <br/>
*/
export interface MasterclassPayment extends  Changed{
change?: Change;
contentId?: number;
masterclassPaymentStatus?: MasterclassPaymentStatus;
paymentReference?: string;
price?: number;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.MasterclassPaymentFilter <br/>
*/
export interface MasterclassPaymentFilter extends  EntityFilter{
changeFilter?: ChangeFilter;
contentId?: number;
contentIds?: number[];
isPaidOrFavorite?: boolean;
masterclassFavoriteContentIds?: number[];
masterclassPaymentStatus?: MasterclassPaymentStatus;
masterclassPaymentStatuses?: MasterclassPaymentStatus[];
notPaid?: boolean;
username?: string;
}

export enum MasterclassPaymentStatus {
INITIALIZED = "INITIALIZED",
PAID = "PAID",
FAILED = "FAILED"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.PlayerPosition <br/>
*/
export interface PlayerPosition extends  Changed{
change?: Change;
contentId?: number;
second?: number;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.PlayerPositionFilter <br/>
*/
export interface PlayerPositionFilter extends  EntityFilter{
changeFilter?: ChangeFilter;
contentIds?: number[];
usernames?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.PlayerTick <br/>
*/
export interface PlayerTick extends  Created{
contentId?: number;
created?: Change;
duration?: number;
second?: number;
totalSecondsViewed?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.QuickAccessView <br/>
*/
export interface QuickAccessView {
interestInfo?: InterestInfo;
latestContent?: ContentView;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.SearchFilter <br/>
*/
export interface SearchFilter extends  EntityFilter{

/**
*	values: [generic, product, content, profile]<br/>
*/
types?: string[];
username?: string;
}

export enum StreamStatus {
SCHEDULED = "SCHEDULED",
IDLE = "IDLE",
ACTIVE = "ACTIVE",
COMPLETED = "COMPLETED"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.StripeMasterclassCheckoutRequest <br/>
*/
export interface StripeMasterclassCheckoutRequest {
contentId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.content.StripeMasterclassCheckoutResponse <br/>
*/
export interface StripeMasterclassCheckoutResponse {
masterclassPayment?: MasterclassPayment;
paymentIntent?: any;
}

