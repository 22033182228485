<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
@if (isScreenSmall) {
  <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
    [navigation]="navigation.default" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container swanVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-6 px-8">
        <mat-icon [svgIcon]="'swan:icon'" class="icon-size-10"></mat-icon>
      </div>
    </ng-container>
  </fuse-vertical-navigation>
}

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

  <!-- Header -->
  <div class="relative flex justify-center w-full overflow-hidden z-49 bg-primary-700 print:hidden">
    <div
      class="max-w-360 w-full sm:py-3 sm:m-8 sm:mb-0 md:mt-12 md:mx-8 md:pt-4 md:pb-3 sm:rounded-t-xl border-b sm:shadow-2xl overflow-hidden bg-card">
      <!-- Top bar -->
      <div class="relative flex flex-auto flex-0 items-center h-16 px-4 md:px-6">
        <!-- Logo -->
        @if (!isScreenSmall) {
          <div class="flex items-center mx-2">
            <!-- Light version -->
            <mat-icon [svgIcon]="'swan:icon'" class="dark:hidden icon-size-10"></mat-icon>
            <!-- Dark version -->
            <mat-icon [svgIcon]="'swan:icon'" class="hidden dark:flex icon-size-10"></mat-icon>
          </div>
        }
        <!-- Navigation toggle button -->
        @if (isScreenSmall) {
          <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
          </button>
        }
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
          <languages></languages>
          <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
          <!-- <shortcuts></shortcuts> -->
          <user></user>
        </div>
      </div>
      <!-- Bottom bar -->
      @if (!isScreenSmall) {
        <div class="relative flex flex-auto flex-0 items-center h-16 px-4">
          <fuse-horizontal-navigation [name]="'mainNavigation'"
          [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        </div>
      }
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-auto justify-center w-full sm:px-8">
    <div class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-xl sm:overflow-hidden bg-default">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
      Otherwise, layout changes won't be registered and the view won't be updated! -->
      @if (true) {
        <router-outlet></router-outlet>
      }
    </div>
  </div>

  <!-- Footer -->
  <div class="relative flex justify-center w-full z-49 print:hidden">
    <div
      class="flex items-center max-w-360 w-full h-14 sm:h-20 px-6 md:px-8 sm:shadow-xl border-t bg-card dark:bg-default">
      <span class="font-medium text-secondary">Swan &copy; {{currentYear}}</span>
    </div>
  </div>

</div>