import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslocoCoreModule } from '@swan/lib/config';
import { ContentModule } from '@swan/lib/content';
import { VideoModule } from '@swan/lib/content/video';
import { ProfileModule } from '@swan/lib/profile';
import { APP_ENVIRONMENT_TOKEN, setEnvironment } from '@yukawa/chain-base-angular-client';
import { YukawaAlertModule } from '@yukawa/chain-base-angular-comp/alert';
import { YukawaCloseButtonModule } from '@yukawa/chain-base-angular-comp/close-button';
import { YukawaConsoleLogAlertsModule } from '@yukawa/chain-base-angular-comp/console-log-alerts';
import { QueryTableEntryFormModule, QueryTableModule } from '@yukawa/chain-base-angular-comp/query-table';
import { getUserLanguage } from '@yukawa/chain-base-angular-comp/shared';
import { YukawaSplashScreenModule } from '@yukawa/chain-base-angular-comp/splash-screen';
import { environment } from 'environments';
import { provideIcons } from './icons/icons.provider';
import { YukawaConfirmationModule } from '@yukawa/chain-base-angular-comp/confirmation';
import { NoItemsModule } from './components/no-items';


setEnvironment(environment);

const availableLanguages = [
    {
        id   : 'en',
        label: 'English',
    },
    {
        id   : 'de',
        label: 'Deutsch',
    },
];

@NgModule({
    imports: [
        ContentModule,
        ProfileModule,
        VideoModule,
        YukawaConfirmationModule,
        NoItemsModule,
        TranslocoCoreModule.forRoot({
            availableLangs      : availableLanguages,
            defaultLang         : getUserLanguage(availableLanguages.map(lang => lang.id), availableLanguages[0].id),
            fallbackLang        : 'en',
            reRenderOnLangChange: true,
            prodMode            : environment.production,
        }),
        YukawaAlertModule.forRoot({
            primary: 'heroicons_solid:check-circle',
            accent : 'heroicons_solid:check-circle',
            warn   : 'heroicons_solid:x-circle',
            basic  : 'heroicons_solid:check-circle',
            info   : 'heroicons_solid:information-circle',
            success: 'heroicons_solid:check-circle',
            warning: 'heroicons_solid:exclamation',
            error  : 'heroicons_solid:x-circle',
            dismiss: 'heroicons_solid:x',
        }),
        YukawaConsoleLogAlertsModule.forRoot(
            !environment.production,
            'outline',
            ...environment.logTypes,
        ),
        YukawaSplashScreenModule.forRoot(true),
        QueryTableModule.forRoot(
            'swan:system-plus-circle',
            'heroicons_outline:refresh',
        ),
        QueryTableEntryFormModule.forRoot(
            'feather:trash-2',
            'feather:x',
            'feather:save',
        ),
        YukawaCloseButtonModule.forRoot(
            'swan:navigation-arrow-left',
            'swan:system-close',
        ),
    ],
    providers: [
        {
            provide : APP_ENVIRONMENT_TOKEN,
            useValue: environment,
        },
        provideIcons()
    ],
})
export class CoreModule
{
    /**
     * Constructor
     */
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule,
    )
    {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
