import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { fadeIn } from '@swan/lib/animations';
import { ImageService, Profile, ProfileService, UserService } from '@swan/lib/profile';
import * as d3 from 'd3';
import { Selection } from 'd3-selection';
import { Nullable } from 'simplytyped';


export type FontWeight = 'regular' | 'bold';

@Component({
    selector   : 'lib-user-icon',
    templateUrl: './user-icon.component.html',
    styleUrls  : ['./user-icon.component.scss'],
    animations : [fadeIn],
})
export class UserIconComponent implements OnInit, OnChanges
{
    @ViewChild('svg') svg: ElementRef<SVGElement> = null as never;
    @Input() profile: Profile                     = null as never;
    @Input() size: number                         = 100;
    @Input() verified: boolean                    = false;
    @Input() swiperLazy: boolean                  = false;
    @Input() displayVerified: boolean | undefined = false;
    @Input() linkToProfile: boolean | undefined   = true;

    @Output() avatarClicked = new EventEmitter<void>();

    private _backgroundColor: string          = '#9e9e9e';
    private _backgroundColor_!: Nullable<string>;
    private _lineHeight                       = 12;
    private _lineHeight_!: Nullable<number>;
    private _font: string                     = 'sans-serif';
    private _font_!: Nullable<string>;
    private _fontSize: number                 = 10;
    private _fontSize_!: Nullable<number>;
    private _fontWeight: FontWeight           = 'regular';
    private _fontWeight_!: Nullable<FontWeight>;
    private _showProfilePicture: boolean      = false;
    private _showProfilePicture_!: Nullable<boolean>;
    private _verifiedTop: number              = 27;
    private _verifiedLeft: number             = 25;
    private _verifiedIconSize: number         = 4;
    private _profilePicture: Nullable<string> = null;
    private _initials: string                 = '';

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _imageService: ImageService,
        private readonly _ngZone: NgZone,
        private readonly _profileService: ProfileService,
        private readonly _userService: UserService,
        private readonly _router: Router,
    )
    {
    }

    //region Public Properties

    public get backgroundColor(): string
    {
        return this._backgroundColor_ || this._backgroundColor;
    }

    @Input()
    set backgroundColor(value: string)
    {
        this._backgroundColor = value;
    }

    get lineHeight(): number
    {
        return this._lineHeight_ ?? this._lineHeight;
    }

    @Input()
    set lineHeight(value: number)
    {
        this._lineHeight = value;
    }

    get font(): string
    {
        return this._font_ || this._font;
    }

    @Input()
    set font(value: string)
    {
        this._font = value;
    }

    get fontSize(): number
    {
        return this._fontSize_ ?? this._fontSize;
    }

    @Input()
    set fontSize(value: number)
    {
        this._fontSize = value;
    }

    get fontWeight(): FontWeight
    {
        return this._fontWeight_ ?? this._fontWeight;
    }

    @Input()
    set fontWeight(value: FontWeight)
    {
        this._fontWeight = value;
    }

    get showProfilePicture(): boolean
    {
        return this._showProfilePicture_ ?? this._showProfilePicture;
    }

    @Input()
    public set showProfilePicture(value: boolean)
    {
        this._showProfilePicture = value;
    }

    get backgroundUrl(): string
    {
        return '';
        // TODO(video/comment): decide to use Gravatar
        /*const hash = '';
        const hash = this.email != null ? Md5.hashStr(this.email.trim().toLowerCase()) : Md5.hashStr('');
        return `https://www.gravatar.com/avatar/${hash}.png?default=blank&size=${this.size * 4}`;*/
    }

    get profilePicture(): Nullable<string>
    {
        return this._profilePicture;
    }

    get initials(): string
    {
        return this._initials;
    }

    get words(): string[]
    {
        return [this.initials];
    }

    get targetWidth(): number
    {
        return Math.sqrt(this.measureWidth(this.initials.trim()) * this.lineHeight);
    }

    get radius(): number
    {
        return Math.max(this.size / 2, 4);
    }

    get verifiedTop(): number
    {
        const percentage = this.getScalePercentage();
        return Math.round(this._verifiedTop * (percentage / 100));
    }

    get verifiedLeft(): number
    {
        const percentage = this.getScalePercentage();
        return Math.round(this._verifiedLeft * (percentage / 100));
    }

    get verifiedIconSize(): number
    {
        const percentage = this.getScalePercentage();
        return Math.round(this._verifiedIconSize * (percentage / 100));
    }

    //endregion

    private static generateUniqueId(): string
    {
        return Math.random().toString(36).substring(2);
    }

    measureWidth(text: string): number
    {
        const context = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D;
        return context.measureText(text).width;
    }

    async ngOnInit(): Promise<void>
    {
        if (!this.profile) {
            this.profile = this._profileService.profile as Profile;
        }
        
        if (!this.profile) {
            this._showProfilePicture = true;
            return;
        }

        if (!this.profile.imageId && this._profileService.profile?.isAdmin) {
            try {
                await this._profileService.loadImage(this.profile);
            }
            catch (error) {
                console.error(error);
            }
        }

        const image = this.profile?.imageUrl;

        this.verified = !!this.profile.verified;

        if (this.profile.username === 'init' ||
            this.profile.username === 'admin' ||
            (this.profile.username.endsWith('swanmirror.com') && !image)) {
            this._font_               = 'Bossa-Medium';
            this._backgroundColor_    = 'black';
            this._fontWeight_         = 'regular';
            this._lineHeight_         = 7.4;
            this._fontSize_           = 6;
            this._showProfilePicture_ = false;
            this._initials            = 'S';
        }
        else {
            this._font_               = null;
            this._backgroundColor_    = null;
            this._fontWeight_         = null;
            this._lineHeight_         = null;
            this._fontSize_           = null;
            this._showProfilePicture_ = null;

            const result = this.profile?.displayName != null ? this.profile.displayName.split(' ') : [this.profile.username];
            if (result.length > 1) {
                this._initials = `${result[0][0]}${result[1][0]}`.toUpperCase();
            }
            else if (result[0].length > 0) {
                if (/[A-Z]/.test(result[0]) && result[0].toUpperCase() !== result[0]) {
                    result[0] = result[0].replace(/[a-z]/g, '');
                }
                result[0]      = result[0].replace(/[^\wd]/g, '');
                this._initials = result[0][0].toUpperCase() + result[0].substring(1, 2);
            }

            this._initials = this._initials.trim();
        }

        if (this._initials.trim().length === 0 || image) {
            this._showProfilePicture = true;
        }

        if (this.showProfilePicture) {
            this._profilePicture = image;
            this._changeDetector.detectChanges();
        }
        else {
            this._changeDetector.detectChanges();
            this.drawCircularAvatar();
        }
    }

    drawCircularAvatar(): void
    {
        if (this.showProfilePicture) {
            return;
        }

        const lines = this.generateLines();

        let textRadius = 0;
        for (let i = 0, n = lines.length; i < n; ++i) {
            const dy   = (Math.abs(i - n / 2 + 0.5) + 0.5) * this.lineHeight;
            const dx   = lines[i].width / 2;
            textRadius = Math.max(textRadius, Math.sqrt(dx ** 2 + dy ** 2));
        }

        let font = this.fontWeight !== 'regular' ? `${this.fontWeight} ` : '';
        font += `${this.fontSize}px ${this.font}`;

        const svg = d3
            .select(this.svg.nativeElement)
            .style('font', font)
            .attr('width', this.size)
            .attr('height', this.size)
            .attr('text-anchor', 'middle');

        const appendCircle = (
            selection: Selection<SVGClipPathElement, unknown, null, undefined>,
            size: number,
            radius: number): void =>
        {
            selection
                .append('circle')
                .attr('cx', size / 2)
                .attr('cy', size / 2)
                .attr('r', radius);
        };

        const id   = UserIconComponent.generateUniqueId();
        const defs = svg.append('defs');

        defs.append('clipPath').attr('id', `image-clip-${id}`).call(appendCircle, this.size, this.radius);

        svg
            .append('circle')
            .attr('cx', this.size / 2)
            .attr('cy', this.size / 2)
            .attr('r', this.radius)
            .attr('fill', this.backgroundColor);

        svg
            .append('text')
            .attr('transform', `translate(${this.size / 2},${this.size / 2}) scale(${this.radius / textRadius})`)
            .selectAll('tspan')
            .data(lines)
            .enter()
            .append('tspan')
            .attr('x', 0)
            .attr('y', (d, i) => (i - 1 / 2 + 0.8) * this.lineHeight)
            .attr('fill', 'white')
            .text(d => d.text);

        svg
            .append('image')
            .attr('xlink:href', this.backgroundUrl)
            .attr('width', this.size)
            .attr('height', this.size)
            .attr('x', 0)
            .attr('y', 0)
            .attr('clip-path', `url(#image-clip-${id})`);
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void>
    {
        if (changes['profile'] && changes['profile'].previousValue) {
            await this.ngOnInit();
        }
    }

    async avatarClick(): Promise<boolean>
    {
        this.avatarClicked.emit();
        let url = '/profile';
        if (this.profile.username !== this._userService.user?.username) {
            url += `/show/${this.profile.username}`;
        }
        return this._ngZone.run(() => this._router.navigate([url]));
    }

    private generateLines(): any[]
    {
        let line;
        let lineWidth0 = Infinity;
        const result   = [];
        for (let i = 0, n = this.words.length; i < n; ++i) {
            const lineText1  = (line ? line.text + ' ' : '') + this.words[i] as string;
            const lineWidth1 = this.measureWidth(lineText1);
            if ((lineWidth0 + lineWidth1) / 2 < this.targetWidth) {
                line = { width: lineWidth0 = lineWidth1, text: lineText1 };
            }
            else {
                lineWidth0 = this.measureWidth(this.words[i]);
                line       = { width: lineWidth0, text: this.words[i] };
                result.push(line);
            }
        }
        return result;
    }

    private getScalePercentage(): number
    {
        return Math.round(this.size / 40 * 100);
    }
}
