import {ChainEventBean, Change, Changed, Created, EntityFilter, Info, InstantRange} from '@yukawa/chain-base-angular-domain';
import {PublicProfile} from './profile';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.BrandPartnerOrder <br/>
*/
export interface BrandPartnerOrder {
brandPartnerOrderPK?: any;
items?: OrderItem[];
shopifyDraftOrderId?: string;
shopifyOrderId?: string;
state?: BrandPartnerOrderStatus;
stripeTransferStatus?: BrandPartnerOrderTransferStatus;
totalPriceAmount?: number;
totalPriceCurrency?: string;
totalShippingPriceAmount?: number;
totalShippingPriceCurrency?: string;
totalTaxAmount?: number;
totalTaxCurrency?: string;
vendor?: Vendor;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.BrandPartnerOrderFilter <br/>
*/
export interface BrandPartnerOrderFilter extends  EntityFilter{
brandPartnerUsername?: string;
parentOrderId?: number;
shopifyOrderId?: string;
vendorId?: number;
withOrderItems?: boolean;
withParentOrder?: boolean;
withProducts?: boolean;
withShop?: boolean;
}

export enum BrandPartnerOrderStatus {
PAYMENT_PENDING = "PAYMENT_PENDING",
TRANSFERRED = "TRANSFERRED",
CANCELLED = "CANCELLED",
PARTIAL_REFUND = "PARTIAL_REFUND",
FULL_REFUND = "FULL_REFUND"
}

export enum BrandPartnerOrderTransferStatus {
CREATED = "CREATED"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.Cart <br/>
*/
export interface Cart {

/**
*	name: The applicable SWAN fee<br/>
*/
feeAmount?: number;

/**
*	@deprecated
*	name: Use 'totalAmount' instead<br/>
*/
grossAmount?: number;

/**
*	name: The summed up price of the items (including taxes) in the shopping cart<br/>
*/
itemTotalAmount?: number;
items?: any[];
itemsGroupedByBrandName?: any[];

/**
*	@deprecated
*	name: Use 'itemAmount' instead<br/>
*/
netAmount?: number;

/**
*	@deprecated
*	name: Use 'feeAmount' instead<br/>
*/
taxAmount?: number;

/**
*	name: The total price of the shopping cart (including taxes and fees)<br/>
*/
totalAmount?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.CartItemFilter <br/>
*/
export interface CartItemFilter extends  EntityFilter{
productId?: number;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.CheckoutEvent <br/>
*/
export interface CheckoutEvent extends  ChainEventBean<Cart> {}

export enum InventoryPolicy {
CONTINUE = "CONTINUE",
DENY = "DENY"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.MarketRegion <br/>
*/
export interface MarketRegion {
currency?: string;
isLocal?: boolean;
name?: string;
regionId?: string;
}

export enum MeasurementType {
UNKNOWN = "UNKNOWN",
AREA = "AREA",
LENGTH = "LENGTH",
VOLUME = "VOLUME",
WEIGHT = "WEIGHT"
}

export enum MeasurementUnit {
UNKNOWN = "UNKNOWN",
CL = "CL",
CM = "CM",
G = "G",
KG = "KG",
L = "L",
M = "M",
M2 = "M2",
M3 = "M3",
MG = "MG",
ML = "ML",
MM = "MM"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.Order <br/>
*/
export interface Order extends  Changed, Created{
billTo?: any;
brandPartnerOrders?: BrandPartnerOrder[];
change?: Change;
created?: Change;
customerId?: string;
feeAmount?: number;

/**
*	@deprecated
*	name: Use 'totalAmount' instead<br/>
*/
grossAmount?: number;
itemTotalAmount?: number;

/**
*	@deprecated
*/
items?: OrderItem[];

/**
*	@deprecated
*	name: Use 'itemAmount' instead<br/>
*/
netAmount?: number;
orderDate?: string;
orderId?: number;
paymentMethod?: string;
shipTo?: any;
shipping?: Shipping;
state?: OrderStatus;

/**
*	@deprecated
*	name: Use 'feeAmount' instead<br/>
*/
taxAmount?: number;
readonly totalAmount?: number;

/**
*	@deprecated
*	name: Use 'customerId' instead<br/>
*/
readonly username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.OrderFilter <br/>
*/
export interface OrderFilter extends  EntityFilter{
customerId?: string;
orderDate?: InstantRange;
orderId?: number;
states?: OrderStatus[];

/**
*	@deprecated
*	name: Use 'customerId' instead<br/>
*/
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.OrderItem <br/>
*/
export interface OrderItem {
orderPositionPK?: any;
productDescription?: string;
productTitle?: string;
productVariant?: ProductVariant;
quantity?: number;
quantityRefunded?: number;
refContentId?: number;
referrer?: string;
state?: OrderItemStatus;
unitPrice?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.OrderItemFilter <br/>
*/
export interface OrderItemFilter extends  EntityFilter{
orderId?: number;
position?: number;
}

export enum OrderItemStatus {
PARTIAL_REFUND = "PARTIAL_REFUND",
FULL_REFUND = "FULL_REFUND"
}

export enum OrderStatus {
PAYMENT_PENDING = "PAYMENT_PENDING",
PROCESSING = "PROCESSING",
TRANSFERRED = "TRANSFERRED",
COMPLETE = "COMPLETE",
PARTIAL_REFUND = "PARTIAL_REFUND",
FULL_REFUND = "FULL_REFUND"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.Product <br/>
*/
export interface Product {
description?: string;
descriptionOverride?: string;
externalId?: string;
hasOnlyDefaultVariant?: boolean;
images?: ProductImage[];
options?: ProductOptionDefinition[];

/**
*	@example 12.59
*/
price?: number;
productId?: number;

/**
*	@example 523
*/
selectedVariantId?: number;
setSelectedVariantId?: undefined | number;
status?: ProductStatus;
swanStatus?: SwanStatus;
title?: string;
titleOverride?: string;
trendingScore?: number;
variants?: ProductVariant[];
vendor?: PublicVendor;
vendorId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.ProductFilter <br/>
*/
export interface ProductFilter extends  EntityFilter{
categoryIds?: string[];
categoryKeywords?: string[];
externalIds?: string[];
loadVendor?: boolean;
ownOnly?: boolean;
productIds?: number[];
statuses?: ProductStatus[];
stream?: string;
swanStatuses?: SwanStatus[];
username?: string;
vendorIds?: number[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.ProductImage <br/>
*/
export interface ProductImage {
url?: string;

/**
*	@deprecated
*/
variantIds?: number[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.ProductOptionDefinition <br/>
*/
export interface ProductOptionDefinition {
name?: string;
type?: Type;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.ProductOptionValue <br/>
*/
export interface ProductOptionValue {

/**
*	@example #6A8D48
*/
color?: string;

/**
*	@example https://example.com/image.jpg
*/
imageUrl?: string;
name?: string;
value?: string;
}

export enum ProductStatus {
DRAFT = "DRAFT",
PUBLISHED = "PUBLISHED",
ARCHIVED = "ARCHIVED"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.ProductVariant <br/>
*/
export interface ProductVariant {
externalId?: string;
images?: ProductImage[];

/**
*	values: [CONTINUE, DENY]<br/>
*/
inventoryPolicy?: InventoryPolicy;
inventoryQuantity?: number;
options?: ProductOptionValue[];

/**
*	@example 12.59
*/
price?: number;
product?: Product;
productId?: number;
productVariantId?: number;
status?: ProductStatus;
title?: string;
titleOverride?: string;
unitPrice?: any;
weight?: number;
weightUnit?: WeightUnit;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.ProductVariantFilter <br/>
*/
export interface ProductVariantFilter extends  EntityFilter{
loadProduct?: boolean;
productVariantIds?: number[];
status?: ProductStatus;
statuses?: ProductStatus[];
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.PublicVendor <br/>
*/
export interface PublicVendor {

/**
*	@deprecated
*/
readonly getId?: number;
profile?: PublicProfile;
vendorId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.Shipping <br/>
*/
export interface Shipping {
info?: Info;
price?: number;
}

export enum SwanStatus {
ACTIVE = "ACTIVE",
BLOCKED = "BLOCKED"
}

export enum Type {
TEXT = "TEXT",
COLOR = "COLOR"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.Vendor <br/>
*/
export interface Vendor {
adminAccessToken?: string;
appClientId?: string;
appClientSecret?: string;
authorizationState?: string;
authorizedScopes?: string[];
domain?: string;
fee?: number;

/**
*	@deprecated
*/
readonly getId?: number;
minimumCheckoutValue?: number;
ownerUsername?: string;
profile?: PublicProfile;
salesChannelId?: string;
status?: VendorStatus;
storefrontPrivateToken?: string;
stripeAccountId?: string;
trendingScore?: number;
type?: VendorType;
vendorId?: number;
webhookSecret?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.VendorEvent <br/>
*/
export interface VendorEvent extends  ChainEventBean<Vendor> {}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.VendorFilter <br/>
*/
export interface VendorFilter extends  EntityFilter{
ownerUsername?: string;
shopDomain?: string;
status?: VendorStatus;
stream?: string;
vendorId?: number;
vendorIds?: number[];
}

export enum VendorStatus {
ACTIVE = "ACTIVE",
ARCHIVED = "ARCHIVED"
}

export enum VendorType {
SHOPIFY = "SHOPIFY"
}

export enum WeightUnit {
GRAMS = "GRAMS",
KILOGRAMS = "KILOGRAMS",
OUNCES = "OUNCES",
POUNDS = "POUNDS"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.marketplace.WishlistItemFilter <br/>
*/
export interface WishlistItemFilter extends  EntityFilter{
productVariantId?: number;
referrer?: string;
username?: string;
}

