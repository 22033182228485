import { OptionCategories, SwanOption } from './options.types';


export class SettingsOptions
{
    public readonly items = new Map<OptionCategories, Array<SwanOption>>();

    public add(category: OptionCategories, option: SwanOption, index?: number): void
    {
        let options: Array<SwanOption>;
        if (!this.items.has(category)) {
            this.items.set(category, options = new Array<SwanOption>());
        }
        else {
            options = this.items.get(category) as Array<SwanOption>;
        }

        if (typeof index === 'number') {
            options.splice(index, 0, option);
        }
        else {
            options.push(option);
        }
    }
}
