import { Comment as IComment } from '@swan/lib/domain';
import { Profile, PublicProfile } from '@swan/lib/profile';
import { AppInjector, Entity } from '@yukawa/chain-base-angular-client';
import { EmojiService } from '@yukawa/chain-base-angular-comp/shared';
import { Change } from '@yukawa/chain-base-angular-domain';
import { SessionService } from '@yukawa/chain-main-angular-session';
import { SwanVideoFile } from './swan-video-file.entity';
import { VideoCommentService } from './video-comment.service';


export interface Comment extends IComment
{
    author: PublicProfile;
    change: Required<Change>;
    children: Array<Comment>;
    commentId: number;
    contentId: number;
    created: Required<Change>;
    likes: number;
    parentCommentId: number;
    text: string;
}

export type CommentType = 'comment' | 'discussion' | 'text' | 'audio' | 'image' | 'pdf';

export class VideoComment extends Entity<Comment>
{
    // Linked objects
    videoFile: SwanVideoFile;
    originalComment: VideoComment = null as never;
    children: Array<VideoComment> = [];

    // Data returned from the comment service
    id: number                = null as never;
    text: string              = null as never;
    abbreviation: string      = null as never;
    author: Profile           = null as never;
    recipient: Profile        = null as never;
    createdAt: string         = null as never;
    timeOfMessage: string     = null as never;
    recipientReadTime: string = null as never;
    commentType: CommentType  = null as never;
    isNew: boolean            = null as never;
    replyToId: number         = null as never;
    likes!: number;

    // Data calculated from the above
    initials: string = null as never;

    // Data used by the UI for rendering
    shouldShowTimestamp: boolean = false;
    shouldShowAvatar: boolean    = false;
    firstInSeries: boolean       = false;
    lastRead: boolean            = false;
    hover: boolean               = false;

    /**
     * Create a new TaskComment
     *
     * @param initialData the Json data from the server
     * @param video        the Video that contains the comment
     */
    constructor(initialData: Comment, video: SwanVideoFile)
    {
        super(); // delay update from json
        this.videoFile = video;
        if (initialData) {
            this.updateFromJson(initialData);
        }
    }

    public get key(): string
    {
        return this.id.toString();
    }

    public get authorIsMe(): boolean
    {
        const cu = AppInjector.get(SessionService).session?.user;
        return this.author.username === cu?.info.username;
    }

    public get recipientIsMe(): boolean
    {
        const cu = AppInjector.get(SessionService).session?.user;
        return this.recipient.username === cu?.info.username;
    }

    public get isBubbleComment(): boolean
    {
        return false;
        return ['text', 'discussion', 'audio', 'image', 'pdf'].includes(this.commentType);
    }

    public get currentUserCanEdit(): boolean
    {
        return this.authorIsMe;
    }

    /**
     * Not used for TaskComments as they are not updated.
     */
    toJson(): any
    {
        return undefined;
    }

    public updateFromJson(data: Comment): void
    {
        this.setFromJson(data, [
            'author',
        ], undefined, {
            author: (autor: PublicProfile) => new Profile(autor),
        });
        this.id = data.commentId;

        const es: EmojiService = AppInjector.get(EmojiService);
        this.text              = es.colonsToNative(data.text);

        const names   = (data.author?.displayName || data.author.username).split(' ');
        this.initials = (names.length > 1
            ? `${names[0][0]}${names[1][0]}`
            : names[0].substring(0, 2)).toUpperCase();

        this.recipient         = this.videoFile.author;
        this.timeOfMessage     = data.created.date as never as string;
        this.recipientReadTime = this.timeOfMessage;
        this.commentType       = 'text';
        this.replyToId         = data.parentCommentId;
        this.isNew             = false;
        this.likes             = data.likes ?? /*AppInjector.get(APP_ENVIRONMENT_TOKEN).production === false
            ? Math.floor(Math.random() * 1000000)
            :*/ 0;
        this.children          = data.children?.map(child => new VideoComment(child, this.videoFile)) ?? [];
    }

    public keyForJson(json: any): string
    {
        return json.id;
    }

    public delete(): void
    {
        const videoCommentService: VideoCommentService = AppInjector.get(VideoCommentService);
        videoCommentService.cache.store                = this.videoFile.commentCache;
        videoCommentService
            .delete({}, { alternateEndpointFormat: '?key=' + this.id })
            .subscribe(
                {
                    next : (response: object) =>
                    {
                        this.videoFile.comments = this.videoFile.comments.filter((e: VideoComment) => e.id !== this.id);
                        this.videoFile.refreshCommentData();
                    },
                    error: (error: any) =>
                    {
                        console.error(error);
                    },
                },
            );
    }
}
