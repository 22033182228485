<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions" class="block min-w-16 min-h-16">
  <lib-user-icon [linkToProfile]="false" [size]="46" class="inline-block"></lib-user-icon>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span transloco="AUTH.SIGNED_IN_AS"></span>
      <span class="mt-1.5 text-md font-medium">{{username}}</span>
    </span>
  </button>

  @if (userIsAdmin) {
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
      <mat-icon [svgIcon]="'swan:system-account-circle'"></mat-icon>
      <span transloco="AUTH.PROFILE"></span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="toggleSettings()">
      <mat-icon>settings</mat-icon>
      <span transloco="SETTINGS.THEME"></span>
    </button>
  }

  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span transloco="AUTH.LOGOUT.LOGOUT"></span>
  </button>
</mat-menu>
