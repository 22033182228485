import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppInjector, ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { EditResult, QueryResult } from '@yukawa/chain-base-angular-domain';
import { UserFilter } from '@yukawa/chain-main-angular-core';
import { User as IUser } from '@yukawa/chain-main-angular-core/gen/chain/main/user';
import { SessionService, SessionStoreService } from '@yukawa/chain-main-angular-session';
import { Credentials } from '@yukawa/chain-security-angular-core';
import { map, Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Role, User } from './user.model';


@Injectable()
export class UserService extends RestAspect
{
    active: boolean                    = false;
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    constructor(
        http: HttpClient,
        configService: ConfigService,
        private _sessionStoreService: SessionStoreService,
    )
    {
        super(http, configService, configService.formatUrl('userUrl'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Returns the current session user. (use in apps)
     */
    get user(): Nullable<User>
    {
        return this._sessionStoreService.getJSON('user');
    }

    /**
     * Returns the user retrieved by {@see UserService.get()} (use in admin)
     */
    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    queryUser(filter: UserFilter): Observable<QueryResult<User>>
    {
        return this.query<any>(
            this.configService.getValue('baseUrl') + this.configService.getValue('userProfileUrl') + '/query',
            filter,
        );
    }

    loadUser(username: string): Observable<User>
    {
        return this.queryUser({
            username,
            withGroupContexts: true,
        }).pipe(map(queryResult => queryResult.items?.[0] as User));
    }

    deleteUser(username: string): Observable<User>
    {
        // delete Profile will delete the user
        // return this.http.delete<any>(this.formatServiceUrl(`/${username}`));
        return this.http.delete<any>(this.configService.formatUrl('adminProfileUrl')+"/", {
            params: {
                key: username,
            },
        });

    }

    saveUser(user: User, method: 'put' | 'post' = 'post'): Observable<User>
    {
        return this.http[method](this.formatServiceUrl(), user)
            .pipe(switchMap(() => this.loadUser(user.username)));
    }

    updateUser(user: User): Observable<User>
    {
        return this.saveUser(user, 'put');
    }

    merge(user: IUser): Observable<User>
    {
        return this.http.post<User>(this.formatServiceUrl('/merge'), user)
            .pipe(switchMap(() => this.loadUser(user.username as string)));
    }

    changePassword(credentials: Credentials): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/password/change'), credentials);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ UserService.user$ Members
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User>
    {
        return this.http.get<User>(this.formatServiceUrl()).pipe(
            tap((user) =>
            {
                this._user.next(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this.http.patch<User>(this.formatServiceUrl(), { user }).pipe(
            map((response) =>
            {
                this._user.next(response);
            }),
        );
    }

    hasRole(role: Role): boolean
    {
        // Pass always for super admin
        const sessionService = AppInjector.get(SessionService);
        return !!sessionService.session?.user?.hasRole(Role.admin)
            || !!sessionService.session?.user?.hasRole(role);
    }
}
