import { Injector } from '@angular/core';
import { Content, FavoriteVideoList, IVideoFile } from '@swan/lib/content';
import { ContentFilter } from '@swan/lib/domain';
import { SwanVideoFile } from './swan-video-file.entity';


export class SwanFavoritesVideoList extends FavoriteVideoList<Content, SwanVideoFile>
{
    constructor(
        injector: Injector,
        filter?: ContentFilter,
    )
    {
        super(injector, filter);
    }

    protected override map(
        fileInfo: IVideoFile<Content>,
        index: number,
        array: Array<IVideoFile<Content>>): SwanVideoFile
    {
        return new SwanVideoFile(fileInfo);
    }
}
