import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fadeIn, fadeOut } from '@swan/lib/animations';
import {
    ImageService,
    InterestInfo,
    InterestInfoService,
    ProfilePersonalizeSteps,
    ProfileService,
} from '@swan/lib/profile';
import { updateList } from '@yukawa/chain-base-angular-client';
import { SessionService } from '@yukawa/chain-main-angular-session';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { Nullable } from 'simplytyped';
import { IPersonalizeHost, PersonalizeService } from '../personalize.service';


export interface InterestsDialogData
{
    displayMore?: boolean;
    interests?: Array<InterestInfo>;
    interestAdded?: (interest: InterestInfo) => void | Promise<void>;
    interestRemoved?: (interest: InterestInfo) => void | Promise<void>;
}

@Component({
    selector   : 'lib-interests',
    templateUrl: './interests.component.html',
    styleUrls  : ['./interests.component.scss'],
    animations : [
        fadeIn,
        fadeOut,
    ],
})
export class InterestsComponent implements OnInit, OnDestroy, IPersonalizeHost
{
    readonly interests                    = new Array<InterestInfo>();
    readonly interestsView                = new Array<InterestInfo>();
    readonly maxTagCount                  = 8;
    selectedInterest: InterestInfo | null = null as never;

    private _displayMore: boolean | undefined;

    constructor(
        private readonly _router: Router,
        private _profileService: ProfileService,
        private _imageService: ImageService,
        private _interestInfoService: InterestInfoService,
        private _personalizeService: PersonalizeService,
        private _sessionService: SessionService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: InterestsDialogData,
        @Optional() private readonly _dialogRef: MatDialogRef<InterestsComponent>,
    )
    {
        if (data?.displayMore) {
            this._displayMore = data.displayMore;
        }
    }

    get username(): Nullable<string>
    {
        return this._profileService.profile?.displayName ?? this._profileService.profile?.username;
    }

    get scrollPadding(): boolean
    {
        return true;
    }

    get step(): ProfilePersonalizeSteps
    {
        return ProfilePersonalizeSteps.interests;
    }

    get displayMore(): boolean
    {
        return this._displayMore ?? !!this._sessionService.session?.user?.isDeveloper;
    }

    @Input()
    set displayMore(value: boolean | undefined)
    {
        this._displayMore = value;
    }

    // eslint-disable-next-line
    get displayPersonalizeMessage(): boolean
    {
        return this._router.url.indexOf('personalize') > -1;
    }

    // eslint-disable-next-line
    get selectedInterestsCount(): number
    {
        return this.interests.reduce((current, next) =>
        {
            current += next.selectedChildren.length;
            return current;
        }, 0);
    }

    async ngOnInit(): Promise<void>
    {
        if (this._profileService.profile?.isAdmin) {
            await firstValueFrom(this._imageService.query({
                related: {
                    entity: 'Interest',
                    module: 'swan',
                },
            }));
        }
        await this.getInterests();

        this._personalizeService.personalizeHost = this;
    }

    public ngOnDestroy(): void
    {
        this._personalizeService.personalizeHost = null;
    }

    async toggleInterestTag(interest: InterestInfo): Promise<void>
    {
        if (this._displayMore && interest.interests.length > 0) {
            this.selectInterest(this.interests.reduce<InterestInfo>((current, next) =>
            {
                if (current) {
                    return current;
                }

                if (next.interests.includes(interest)) {
                    return next;
                }

                return current;
            }, null as never));
            return;
        }
        const selected = !interest.selected;
        if (interest.selected) {
            await this.removeInterest(interest);
        }
        else {
            if (this.selectedInterestsCount >= this.maxTagCount) {
                return;
            }
            await this.addInterest(interest);
        }
        interest.selected = selected;
    }

    selectInterest(interest: InterestInfo): void
    {
        this.selectedInterest     = interest;
        this.interestsView.length = 0;
        this.interestsView.push(...interest.interests);
    }

    back(): void
    {
        this.selectedInterest     = null;
        this.interestsView.length = 0;
        this.interestsView.push(...this.interests);
    }

    private async getInterests(): Promise<void>
    {
        const interests = this.data?.interests || await this._interestInfoService.loadInterests();
        updateList(this.interests, interests);
        updateList(this.interestsView, interests);
    }

    private async addInterest(interest: InterestInfo): Promise<void>
    {
        if (this._dialogRef && this.data.interestAdded) {
            await this.data.interestAdded(interest);
        }
        else {
            await lastValueFrom(this._profileService.addInterest(interest.id));
        }
    }

    private async removeInterest(interest: InterestInfo): Promise<void>
    {
        if (this._dialogRef && this.data.interestRemoved) {
            await this.data.interestRemoved(interest);
        }
        else {
            await lastValueFrom(this._profileService.removeInterest(interest.id));
        }
    }
}
