import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CommentFilter } from '@swan/lib/domain';
import { CachedRestEntityService, ConfigService, HttpOptions } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable, of, switchMap, tap } from 'rxjs';
import { PlainObject } from 'simplytyped';
import { SwanVideoFile } from './swan-video-file.entity';
import { Comment, VideoComment } from './video-comment.entity';


@Injectable()
export class VideoCommentService extends CachedRestEntityService<VideoComment>
{
    public readonly commentAdded$ = new EventEmitter<VideoComment>();
    public readonly entityName    = 'TaskComment';

    protected readonly endpointFormat: string;

    private readonly commentEndpointFormat          = 'content/:contentId:';
    private readonly createCommentEndpointFormat    = 'create';
    private readonly discussionEndpointFormat       = this.commentEndpointFormat;
    private readonly extensionGrantEndpointFormat   = this.commentEndpointFormat;
    private readonly requestExtensionEndpointFormat = this.commentEndpointFormat;

    constructor(http: HttpClient, configService: ConfigService)
    {
        super(http, configService, configService.formatUrl('commentUrl'));

        this.endpointFormat = this.commentEndpointFormat;
    }

    public keyForJson(json: any): string
    {
        return json.id;
    }

    public count(filter: CommentFilter): Observable<number>
    {
        return this.http.post<number>(this.formatServiceUrl('/count'), filter);
    }

    /**
     * Query for content comments, and update unread message count.
     *
     * @param pathIds The details needed to query the API
     * @param other Contains the related Tasks used to construct the TaskComments
     * @param options
     */
    public override query<E = VideoComment>(
        pathIds?: string | object,
        other?: any | object,
        options?: HttpOptions): Observable<QueryResult<E>>
    {
        return super.query<E>(pathIds as object, other, options).pipe(
            tap((result) =>
            {
                // Access the task and set the number of new comments to 0 - they are now read on the server
                const task            = other as any;
                task.num_new_comments = 0;
            }),
            switchMap((result) =>
            {
                const newResult = new Array<E>();

                for (const _item of result.items) {
                    newResult.push(_item);
                    newResult.push(...((_item as unknown as VideoComment).children) as never as Array<E>);
                }

                result.items = newResult;

                return of(result);
            }),
        );
    }

    public addComment(
        video: SwanVideoFile,
        data: string | File | Blob,
        commentType: string,
        originalComment?: VideoComment,
        prompts?: Blob[],
    ): Observable<VideoComment>
    {
        const opts: HttpOptions = { alternateEndpointFormat: this.createCommentEndpointFormat };
        let body: object | FormData;

        if (typeof data === 'string') {
            body = {
                contentId: video.fileInfo.contentId,
                text     : data,
            } as Comment;
            if (originalComment) {
                (body as PlainObject)['parentCommentId'] = originalComment.id;
            }
        }
        else {
            const formData = new FormData();
            if (originalComment) {
                formData.append('parentCommentId', originalComment.id.toString());
            }

            // Based on the comment type - add to the body and configure the end point
            if (commentType === 'text') {
                formData.append('comment', data);
            }
            else if (commentType === 'discussion') {
                opts.alternateEndpointFormat = this.discussionEndpointFormat;
                for (const prompt of prompts as Blob[]) {
                    formData.append('attachments[]', prompt);
                }
            }
            else {
                formData.append('attachment', data);
            }
            body = formData;
        }

        this.cache.store = video.commentCache;
        const self       = this;
        return this.create({}, body, video, opts).pipe(
            tap((tc: VideoComment) =>
            {
                tc.isNew = true;
                if (originalComment) {
                    tc.originalComment = originalComment;
                    video.comments.splice(video.comments.indexOf(originalComment) + 1, 0, tc);
                }
                else {
                    video.comments.splice(0, 0, tc);
                }

                video.refreshCommentData();
                self.commentAdded$.emit(tc);
            }),
        );
    }

    /**
     * Create a Task Comment - use the type to determine the exact object type to return.
     */
    protected createInstanceFrom(json: Comment, other: SwanVideoFile): VideoComment
    {
        return new VideoComment(json, other);
    }
}
