import { HttpErrorResponse } from '@angular/common/http';
import * as i0 from '@angular/core';
import { InjectionToken, ElementRef, Component, ChangeDetectionStrategy, Inject, ViewChildren, HostBinding, NgModule } from '@angular/core';
import * as i2 from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { LogTypes, AppInjector, APP_ENVIRONMENT_TOKEN } from '@yukawa/chain-base-angular-client';
import * as i1 from '@yukawa/chain-base-angular-comp/alert';
import { YukawaAlertComponent, YukawaAlertModule } from '@yukawa/chain-base-angular-comp/alert';
import { shake, expandCollapse } from '@yukawa/chain-base-angular-comp/animations';
import { uniqueId } from 'lodash-es';
import { Subject, takeUntil, filter } from 'rxjs';
import * as i3 from '@ngneat/transloco';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i6 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
function YukawaConsoleLogAlertsComponent_button_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 4);
    i0.ɵɵlistener("click", function YukawaConsoleLogAlertsComponent_button_0_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.closeAll());
    });
    i0.ɵɵelementStart(1, "mat-icon", 5);
    i0.ɵɵtext(2, "close");
    i0.ɵɵelementEnd()();
  }
}
function YukawaConsoleLogAlertsComponent_yukawa_alert_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "yukawa-alert", 6)(1, "span", 7);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const _alert_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("@expandCollapse", undefined)("name", _alert_r3.name)("appearance", ctx_r1.config.appearance)("@shake", _alert_r3.type === "error");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(_alert_r3.title);
  }
}
const CONSOLE_LOG_ALERT_CONFIG_TOKEN = new InjectionToken('CONSOLE_LOG_ALERT_CONFIG_TOKEN');
class YukawaConsoleLogAlertsComponent {
  _changeDetector;
  config;
  _yukawaAlertService;
  _router;
  _translocoService;
  alertComponents;
  alerts = new Array();
  #detectingChanges = false;
  #unsubscribeAll = new Subject();
  constructor(_changeDetector, config, _yukawaAlertService, _router, _translocoService) {
    this._changeDetector = _changeDetector;
    this.config = config;
    this._yukawaAlertService = _yukawaAlertService;
    this._router = _router;
    this._translocoService = _translocoService;
    window.logService.logged.subscribe(this.logged, this);
  }
  get classNames() {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      'hidden': this.alerts.length === 0,
      'w-fit': true,
      'max-w-screen': true,
      'left-0': true,
      'right-0': true,
      'mx-auto': true,
      'top-24': true,
      'max-h-2/3': true,
      'bg-gray-500/25': this.alerts.length > 1,
      'rounded-md': this.alerts.length > 1,
      'p-6': true
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }
  ngOnInit() {
    this._yukawaAlertService.onDismiss.pipe(takeUntil(this.#unsubscribeAll)).subscribe(name => {
      const alertIndex = this.alerts.findIndex(alert => alert.name === name);
      this.alerts.splice(alertIndex, 1);
    });
    this._router.events.pipe(takeUntil(this.#unsubscribeAll), filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.alerts.length = 0;
    });
  }
  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.#unsubscribeAll.next(null);
    this.#unsubscribeAll.complete();
    window.logService.logged.unsubscribe(this.logged);
  }
  closeAll() {
    this.alerts.length = 0;
  }
  logged(sender, ea) {
    // Cancel dialog show to prevent possible event round trips.
    if (this.#detectingChanges) {
      return;
    }
    if (!this.config.logTypes.some(logType => logType === ea.type)) {
      return;
    }
    let alertType;
    switch (ea.type) {
      case LogTypes.debug:
        alertType = 'basic';
        break;
      case LogTypes.info:
      case LogTypes.log:
        alertType = 'info';
        break;
      case LogTypes.error:
        alertType = ea.type;
        break;
      case LogTypes.warn:
        alertType = 'warning';
        break;
    }
    let alert;
    if (!window.navigator.onLine) {
      alert = {
        type: alertType,
        message: this._translocoService.translate('ERROR.NO_INTERNET_CONNECTION')
      };
      const onlineListener = () => {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
        window.removeEventListener('online', onlineListener);
      };
      window.addEventListener('online', onlineListener);
    } else if (!this.config.enabled) {
      return;
    } else {
      let error = ea.data.find(data => data instanceof Error || data instanceof HttpErrorResponse);
      if (error) {
        if (error.rejection) {
          error = error.rejection;
        }
        alert = this._yukawaAlertService.generateErrorAlert(error, AppInjector.get(APP_ENVIRONMENT_TOKEN));
      } else {
        alert = {
          type: alertType,
          message: ea.message
        };
      }
    }
    const lines = alert.message.split('\n');
    if (lines.length === 1) {
      alert.title = this._translocoService.translate('ERROR.TITLE.' + ea.type.toUpperCase());
    } else {
      alert.title = lines.shift();
      alert.message = lines.join('\n');
    }
    const iYukawaAlert = this.alerts.find(_alert => (!window.navigator.onLine || _alert.title === alert.title) && (!_alert.message || _alert.message === alert.message));
    if (iYukawaAlert) {
      if (!window.navigator.onLine) {
        return;
      }
      this.alerts.splice(this.alerts.indexOf(iYukawaAlert), 1);
    }
    alert.name = uniqueId('app_alert_');
    this.alerts.push(alert);
    this.#detectingChanges = true;
    this._changeDetector.detectChanges();
    this._yukawaAlertService.show(alert.name, alert);
    this._changeDetector.detectChanges();
    this.#detectingChanges = false;
    setTimeout(() => {
      this.alertComponents.last.nativeElement.scrollIntoView({
        block: 'end',
        behavior: 'smooth'
      });
    }, 0);
  }
  /** @nocollapse */
  static ɵfac = function YukawaConsoleLogAlertsComponent_Factory(t) {
    return new (t || YukawaConsoleLogAlertsComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(CONSOLE_LOG_ALERT_CONFIG_TOKEN), i0.ɵɵdirectiveInject(i1.YukawaAlertService), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i3.TranslocoService));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: YukawaConsoleLogAlertsComponent,
    selectors: [["yukawa-console-log-alerts"]],
    viewQuery: function YukawaConsoleLogAlertsComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(YukawaAlertComponent, 5, ElementRef);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.alertComponents = _t);
      }
    },
    hostVars: 2,
    hostBindings: function YukawaConsoleLogAlertsComponent_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.classNames);
      }
    },
    decls: 4,
    vars: 2,
    consts: [["mat-icon-button", "", "class", "absolute top-8 right-8 z-10 bg-gray-400/50 hover:bg-gray-500/50 transition-all", 3, "click", 4, "ngIf"], [1, "relative", "overflow-y-scroll"], [1, "grid", "grid-cols-1", "gap-y-4"], ["class", "block ml-auto mr-auto min-h-15 h-auto w-auto lg:w-200", "showIcon", "true", "dismissible", "true", "dismissed", "true", 3, "name", "appearance", 4, "ngFor", "ngForOf"], ["mat-icon-button", "", 1, "absolute", "top-8", "right-8", "z-10", "bg-gray-400/50", "hover:bg-gray-500/50", "transition-all", 3, "click"], [1, "text-white"], ["showIcon", "true", "dismissible", "true", "dismissed", "true", 1, "block", "ml-auto", "mr-auto", "min-h-15", "h-auto", "w-auto", "lg:w-200", 3, "name", "appearance"], ["yukawaAlertTitle", ""]],
    template: function YukawaConsoleLogAlertsComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, YukawaConsoleLogAlertsComponent_button_0_Template, 3, 0, "button", 0);
        i0.ɵɵelementStart(1, "div", 1)(2, "div", 2);
        i0.ɵɵtemplate(3, YukawaConsoleLogAlertsComponent_yukawa_alert_3_Template, 3, 5, "yukawa-alert", 3);
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.alerts.length > 1);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngForOf", ctx.alerts);
      }
    },
    dependencies: [i1.YukawaAlertComponent, i4.NgForOf, i4.NgIf, i5.MatIconButton, i6.MatIcon],
    styles: ["[_nghost-%COMP%]{position:absolute;z-index:99999}"],
    data: {
      animation: [shake, expandCollapse]
    },
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(YukawaConsoleLogAlertsComponent, [{
    type: Component,
    args: [{
      selector: 'yukawa-console-log-alerts',
      animations: [shake, expandCollapse],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<button mat-icon-button *ngIf=\"alerts.length > 1\"\n        class=\"absolute top-8 right-8 z-10 bg-gray-400/50 hover:bg-gray-500/50 transition-all\"\n        (click)=\"closeAll()\">\n    <mat-icon class=\"text-white\">close</mat-icon>\n</button>\n<div class=\"relative overflow-y-scroll\">\n    <div class=\"grid grid-cols-1 gap-y-4\">\n        <!-- Alert -->\n        <yukawa-alert *ngFor=\"let _alert of alerts\" @expandCollapse\n                      [name]=\"_alert.name\"\n                      class=\"block ml-auto mr-auto min-h-15 h-auto w-auto lg:w-200\"\n                      [appearance]=\"config.appearance\"\n                      showIcon=\"true\"\n                      dismissible=\"true\"\n                      dismissed=\"true\"\n                      [@shake]=\"_alert.type === 'error'\">\n            <span yukawaAlertTitle>{{ _alert.title }}</span>\n        </yukawa-alert>\n    </div>\n</div>\n",
      styles: [":host{position:absolute;z-index:99999}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CONSOLE_LOG_ALERT_CONFIG_TOKEN]
    }]
  }, {
    type: i1.YukawaAlertService
  }, {
    type: i2.Router
  }, {
    type: i3.TranslocoService
  }], {
    alertComponents: [{
      type: ViewChildren,
      args: [YukawaAlertComponent, {
        read: ElementRef
      }]
    }],
    classNames: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class YukawaConsoleLogAlertsModule {
  static forRoot(enabled, appearance, ...logTypes) {
    return {
      ngModule: YukawaConsoleLogAlertsModule,
      providers: [{
        provide: CONSOLE_LOG_ALERT_CONFIG_TOKEN,
        useValue: {
          enabled,
          appearance,
          logTypes
        }
      }]
    };
  }
  /** @nocollapse */
  static ɵfac = function YukawaConsoleLogAlertsModule_Factory(t) {
    return new (t || YukawaConsoleLogAlertsModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: YukawaConsoleLogAlertsModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [YukawaAlertModule, CommonModule, MatButtonModule, MatIconModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(YukawaConsoleLogAlertsModule, [{
    type: NgModule,
    args: [{
      imports: [YukawaAlertModule, CommonModule, MatButtonModule, MatIconModule],
      declarations: [YukawaConsoleLogAlertsComponent],
      exports: [YukawaConsoleLogAlertsComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CONSOLE_LOG_ALERT_CONFIG_TOKEN, YukawaConsoleLogAlertsComponent, YukawaConsoleLogAlertsModule };
