<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-indigo-800 text-white print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'" [navigation]="navigation.futuristic" [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container swanVerticalNavigationHeader>
    <!-- Logo -->
    <div class="flex items-center h-20 p-6 pb-0">
      <mat-icon [svgIcon]="'swan:icon'" class="icon-size-10"></mat-icon>
    </div>
  </ng-container>
  <!-- Navigation footer hook -->
  <ng-container swanVerticalNavigationFooter>
    <!-- User -->
    <div class="flex items-center w-full px-6 py-8 border-t">
      <user></user>
      <div class="flex flex-col w-full ml-4 overflow-hidden">
        <div
          class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden leading-normal text-current opacity-80">
          {{profile.displayName}}
        </div>
        <div
          class="w-full mt-0.5 whitespace-nowrap text-sm overflow-ellipsis overflow-hidden leading-normal text-current opacity-50">
          brian.hughes&#64;company.com
        </div>
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button class="mr-2" mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <languages></languages>
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <!-- <shortcuts></shortcuts> -->

    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
    Otherwise, layout changes won't be registered and the view won't be updated! -->
    @if (true) {
      <router-outlet></router-outlet>
    }
  </div>

  <!-- Footer -->
  <div
    class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
    <span class="font-medium text-secondary">Swan &copy; {{currentYear}}</span>
  </div>

</div>