import { AppEnvironment, IEnvironment } from '@swan/lib/config';
import { LogTypes } from '@yukawa/chain-base-angular-client';


export const environment: IEnvironment = {
    app        : AppEnvironment.admin,
    production : false,
    logTypes   : [
        LogTypes.warn,
        LogTypes.error,
    ],
    _wsEndpoint: '',
    wsEndpoint : '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RTCPeerConfiguration: {
        iceServers: [
            {
                urls: [],
            },
        ],
    },

    mirror: null,
};
