<div class="flex flex-col w-full">

    <div>
        <h2 transloco="PERSONALIZE.BIRTHDATE.WHEN_BORN"></h2>
        <p class="pt-2" transloco="PERSONALIZE.BIRTHDATE.BEAUTY_NEEDS"></p>
    </div>

    <div class="pt-6 flex flex-col-3 justify-center">

        <form [formGroup]="birthdateForm" class="space-x-3">
            <mat-form-field class="w-20" appearance="fill" color="accent">
                <input matInput #dayInput type="text" id="day" name="day" formControlName="day" required
                       inputmode="numeric" pattern="[0-9]+" placeholder="DD"
                       class="font-semibold text-2xl text-center" maxlength="2" min="1" max="31"
                       [cdkTrapFocusAutoCapture]="true" [cdkTrapFocus]="true"
                       (keyup)="inputKeyUp(dayInput, monthInput)"
                />
            </mat-form-field>

            <mat-form-field class="w-20" appearance="fill" color="primary">
                <input matInput #monthInput type="text" id="month" name="month" formControlName="month" required
                       inputmode="numeric" pattern="[0-9]+" placeholder="MM"
                       class="font-semibold text-2xl text-center" maxlength="2" min="1" max="12"
                       (keyup)="inputKeyUp(monthInput, yearInput)">
            </mat-form-field>

            <mat-form-field class="w-30" appearance="fill" color="warn">
                <input matInput #yearInput type="text" id="year" name="year" formControlName="year" required
                       inputmode="numeric" pattern="[0-9]+" placeholder="YYYY"
                       class="font-semibold text-2xl text-center" maxlength="4" [min]="minYear" [max]="maxYear">
            </mat-form-field>
        </form>

    </div>

</div>
