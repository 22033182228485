import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NoItemsComponent } from './no-items.component';


@NgModule({
    declarations: [
        NoItemsComponent,
    ],
    imports     : [
        CommonModule,
        MatIconModule,
    ],
    exports     : [
        NoItemsComponent,
    ],
})
export class NoItemsModule
{
}
