import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SettingsOptions } from './options.entity';
import { OptionCategories, SwanOption } from './options.types';


@Component({
    selector   : 'lib-settings-options',
    templateUrl: './options.component.html',
    styleUrls  : ['./options.component.scss'],
})
export class SettingsOptionsComponent implements OnInit
{
    @Input()
    public readonly options!: SettingsOptions;

    constructor()
    {
    }

    public get displayInterests(): boolean
    {
        return Array.from(this.options.items.values()).some(item => item.some(_item => _item.type === 'interests'));
    };

    ngOnInit(): void
    {
    }

    /**
     * Preserve original property order
     *
     * @param a
     * @param b
     */
    public originalOrder = (
        a: KeyValue<OptionCategories, Array<SwanOption>>,
        b: KeyValue<OptionCategories, Array<SwanOption>>): number => 0;
}
