import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { SkinType } from '@swan/lib/domain';
import { ProfilePersonalizeSteps, ProfileService } from '@swan/lib/profile';
import { IPersonalizeHost, PersonalizeService } from '../personalize.service';
import { ProfileComponentBase } from '../profile-component-base';


export enum SkinTypes
{
    /* eslint-disable @typescript-eslint/naming-convention */
    PALE_WHITE = 1,
    FAIR_WHITE,
    DARKER_WHITE,
    LIGHT_BROWN,
    BROWN,
    DARK_BROWN
    /* eslint-enable @typescript-eslint/naming-convention */
}

@Component({
    selector   : 'lib-skin-type',
    templateUrl: './skin-type.component.html',
    styleUrls  : ['./skin-type.component.scss'],
})
export class SkinTypeComponent extends ProfileComponentBase implements OnInit, OnDestroy, IPersonalizeHost
{
    disabled: boolean      = false;
    skinTypes              = new Array<{
        name: SkinType;
        type: SkinTypes;
    }>();
    private _skinTypeArray = new Array<SkinType>();

    constructor(
        _profileService: ProfileService,
        private _personalizeService: PersonalizeService,
    )
    {
        super(_profileService);
    }

    get skinType(): SkinType
    {
        return this.profile.skinType;
    }

    get valid(): boolean
    {
        return this.profile.skinType != null;
    }

    get step(): ProfilePersonalizeSteps
    {
        return ProfilePersonalizeSteps.skinType;
    }

    get working(): boolean
    {
        return this.disabled;
    }

    override ngOnInit(): void
    {
        super.ngOnInit();
        this.skinTypes = Object.values(SkinType).map(skinType => ({
            name: skinType,
            type: SkinTypes[skinType],
        })).reverse();
        this._skinTypeArray.push(this.profile.skinType);
        this._personalizeService.personalizeHost = this;
    }

    ngOnDestroy(): void
    {
        this._personalizeService.personalizeHost = null;
    }

    public skinTypeChanged(event: MatSelectionListChange): void
    {
        if (!event.options[0].selected) {
            event.options[0].selected = true;
            return;
        }
        this.profile.skinType = event.options[0].value.name;
    }

    public async next(): Promise<boolean>
    {
        return this.saveProfile();
    }

    override async saveProfile(): Promise<boolean>
    {
        this.disabled = true;
        try {
            const result = await super.saveProfile();
            if (result) {
                this._skinTypeArray.length = 0;
                this._skinTypeArray.push(this.profile.skinType);
            }
            return result;
        }
        catch (error) {
            console.error(error);
            return false;
        }
        finally {
            this.disabled = false;
        }
    }
}
