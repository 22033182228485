import {ChainEventBean, Change, ChangeFilter, Changed, Created, EntityFilter, Info} from '@yukawa/chain-base-angular-domain';
import {User, UserFilter} from '@yukawa/chain-main-angular-core';
import {Comment, Content} from './content';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.BlockedProfile <br/>
*/
export interface BlockedProfile extends  Changed{
blockedUser?: PublicProfile;
change?: Change;
username?: string;
usernameBlocked?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.BlockedProfileFilter <br/>
*/
export interface BlockedProfileFilter extends  EntityFilter{
username?: string;
usernameBlocked?: string;
usernames?: string[];
usernamesBlocked?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.Complaint <br/>
*/
export interface Complaint extends  Changed, Created{
change?: Change;
complaintId?: number;
created?: Change;

/**
*	@example I am not OK with this
*/
description?: string;

/**
*	@example speech
*/
reason?: string;
refComment?: Comment;

/**
*	@example 100
*/
refCommentId?: number;
refContent?: Content;

/**
*	@example 100
*/
refContentId?: number;
refProfile?: PublicProfile;

/**
*	@example user1
*/
refProfileId?: string;

/**
*	@example action taken
*/
remark?: string;

/**
*	@example NEW
*/
status?: ComplaintStatus;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ComplaintFilter <br/>
*/
export interface ComplaintFilter extends  EntityFilter{
complaintId?: number;
reason?: string;
status?: ComplaintStatus[];
}

export enum ComplaintStatus {
NEW = "NEW",
RESOLVED = "RESOLVED",
REJECTED = "REJECTED"
}

export enum Gender {
MALE = "MALE",
FEMALE = "FEMALE",
DIVERSE = "DIVERSE"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.Interest <br/>
*/
export interface Interest extends  Changed, Created{
change?: Change;
children?: Interest[];
contentAssignments?: number;
created?: Change;
desc?: string;
i18n?: {[key: string]: Info};
imageId?: string;
interestId?: string;
name?: string;
parent?: Interest;
position?: number;
profileAssignments?: number;
recentInterest?: RecentInterest;
shortName?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.InterestFilter <br/>
*/
export interface InterestFilter extends  EntityFilter{
desc?: string;
enrichChildren?: boolean;
enrichRecentInterest?: boolean;
interestId?: string;
interestIds?: string[];
name?: string;
parentInterestId?: string;
shortName?: string;
underInterestIds?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.InterestInfo <br/>
*/
export interface InterestInfo extends  Info{
assignments?: number;
imageId?: string;
interests?: InterestInfo[];
lastContentUpload?: Date;
lastUploadedContentId?: number;
position?: number;
selected?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.Profile <br/>
*/
export interface Profile extends  Changed, Created{
bgImageId?: string;
businessProfile?: boolean;
change?: Change;
completenessRank?: number;
countryCode?: string;
created?: Change;
currentOffersConsent?: boolean;
dateOfBirth?: Date;
description?: string;
displayName?: string;
earlyAccessConsent?: boolean;
followersCount?: number;
gender?: Gender;
readonly getExperience?: number;
readonly getLevel?: number;
imageId?: string;
interests?: string[];
isVendor?: boolean;
lastActiveAt?: string;
latestNewsConsent?: boolean;
marketRegionId?: string;
nickname?: string;
skinType?: SkinType;
skipNotifications?: string[];
uploadsCount?: number;
username?: string;
verified?: string;
verifiedRank?: number;
website?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ProfileEvent <br/>
*/
export interface ProfileEvent extends  ChainEventBean<Profile> {}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ProfileFilter <br/>
*/
export interface ProfileFilter extends  EntityFilter{
businessProfile?: boolean;
displayName?: string;
nickname?: string;

/**
*	@example use*
*/
nicknameKeyword?: string;
nicknames?: string[];
username?: string;
usernames?: string[];
verified?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ProfileFollow <br/>
*/
export interface ProfileFollow extends  Changed{
change?: Change;
username?: string;
usernameFollowed?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ProfileFollowEvent <br/>
*/
export interface ProfileFollowEvent extends  ChainEventBean<ProfileFollow> {}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ProfileFollowFilter <br/>
*/
export interface ProfileFollowFilter extends  EntityFilter{
changeFilter?: ChangeFilter;
username?: string;
usernameFollowed?: string;
usernames?: string[];
usernamesFollowed?: string[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.PublicProfile <br/>
*/
export interface PublicProfile extends  Changed, Created{
bgImageId?: string;
businessProfile?: boolean;
change?: Change;
completenessRank?: number;
created?: Change;
description?: string;
displayName?: string;
followedByMe?: boolean;
followers?: ProfileFollow[];
followersCount?: number;
imageId?: string;
isVendor?: boolean;
level?: number;
nickname?: string;
setFollowedByMe?: undefined | boolean;
uploadsCount?: number;
username?: string;
verified?: string;
verifiedRank?: number;
website?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.PublicProfileFilter <br/>
*/
export interface PublicProfileFilter extends  EntityFilter{
businessProfile?: boolean;
created?: ChangeFilter;
enrichFollowedByMe?: boolean;
followedByMe?: boolean;
followedByUsername?: string;
nickname?: string;

/**
*	@example use*
*/
nicknameKeyword?: string;
nicknames?: string[];
username?: string;
usernames?: string[];
withoutBlockedByMe?: boolean;
withoutBlockedByUsername?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.RecentInterest <br/>
*/
export interface RecentInterest {
interestId?: string;
lastUploadDate?: Date;
lastUploadedContentId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ReservedUserTag <br/>
*/
export interface ReservedUserTag {
userTag?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.ReservedUserTagFilter <br/>
*/
export interface ReservedUserTagFilter extends  EntityFilter{
userTag?: string;
userTags?: string[];
}

export enum SkinType {
PALE_WHITE = "PALE_WHITE",
FAIR_WHITE = "FAIR_WHITE",
DARKER_WHITE = "DARKER_WHITE",
LIGHT_BROWN = "LIGHT_BROWN",
BROWN = "BROWN",
DARK_BROWN = "DARK_BROWN"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.SwanUser <br/>
*/
export interface SwanUser {
email?: string;
lang?: string;
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.SwanUserFilter <br/>
*/
export interface SwanUserFilter extends  EntityFilter{
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.UserProfile <br/>
*/
export interface UserProfile extends  User{
change?: Change;
profile?: PublicProfile;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.swan.base.core.domain.profile.UserProfileFilter <br/>
*/
export interface UserProfileFilter extends  UserFilter{}

