@if (data) {
<div matDialogTitle transloco="VIDEO.RECORD.TAGS.LABEL" [translocoParams]="{
    selectedTagsCount: selectedInterestsCount,
    maxTagCount: maxTagCount
}"></div>
}
<div class="flex flex-col w-full space-y-5" [ngClass]="{
    'pb-4': !displayPersonalizeMessage
}">

  @if (displayPersonalizeMessage) {
    <div class="mb-8">
      <h2 transloco="PERSONALIZE.INTEREST.WHAT_ARE_YOU_INTO"
      [translocoParams]="{name: username}"></h2>
      <p class="pt-2" transloco="PERSONALIZE.INTEREST.SELECT"
        [translocoParams]="{ interest: selectedInterest?.name }">
        @if (selectedInterest !== null) {
          <span class="italic" @fadeOut @fadeIn>{{ selectedInterest.name }}</span>
        }
      </p>
    </div>
  }

  @for (interestCategory of interestsView; track interestCategory) {
    <div>
      <div class="flex" @fadeIn @fadeOut>
        <h3 class="mb-2">{{interestCategory.name}}</h3>
        @if (displayMore) {
          @switch (interestCategory.hasMore) {
            @case (true) {
              <a title=""
                class="ml-auto flex items-center font-sm font-sans font-semibold text-gray-500"
                (click)="selectInterest(interestCategory)">
                <span transloco="BUTTON.MORE"></span>
                <mat-icon class="-ml-0.5" svgIcon="swan:navigation-chevron-right"></mat-icon>
              </a>
            }
            @case (false) {
              @if (selectedInterest !== null) {
                <a
                  class="ml-auto flex items-center font-sm font-sans font-semibold text-gray-500" title=""
                  (click)="back()">
                  <mat-icon class="-ml-0.5" svgIcon="swan:navigation-chevron-left"></mat-icon>
                  <span transloco="BUTTON.BACK"></span>
                </a>
              }
            }
          }
        }
      </div>
      @if (interestCategory.interests) {
        <div
          class="m-0 grid grid-cols-2 sm:grid-cols-4 -ml-2 pr-2">
          @for (interest of interestCategory.interests; track interest) {
            <a (click)="toggleInterestTag(interest)"
               [title]="interest.name" @fadeIn @fadeOut [ngClass]="{
                    'selected': interest.selected || interest.hasSelectedChildren
                 }" class="interest relative ml-2 mt-2 overflow-hidden rounded-lg">
              <img class="interest-image outline object-scale-down place-self-center"
                [src]="interest.imageUrl" [alt]="">
              <div class="backdrop"></div>
              <div class="backdrop-selected"></div>
              @if (interest.selected || interest.hasSelectedChildren) {
                <mat-icon svgIcon="swan:system-check-circle-filled"
                          class="absolute inline-block top-2 right-2 text-white" [ngClass]="{
                                'text-white': interest.selected,
                                'text-gray-500': !interest.selected && interest.hasSelectedChildren
                          }"></mat-icon>
              }
              <span
              class="absolute inline-block bottom-2.5 left-3 font-sans font-semibold tracking-wide leading-4 text-base text-white">{{ interest.name }}</span>
            </a>
          }
        </div>
      }
    </div>
  }

</div>
