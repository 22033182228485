import { Injectable, OnInit } from '@angular/core';
import { Profile, ProfileService } from '@swan/lib/profile';
import { lastValueFrom } from 'rxjs';


@Injectable()
export abstract class ProfileComponentBase implements OnInit
{
    private _profile!: Profile;

    protected constructor(
        protected _profileService: ProfileService,
    )
    {
    }

    public get profile(): Profile
    {
        return this._profile;
    }

    // eslint-disable-next-line @angular-eslint/contextual-lifecycle
    public ngOnInit(): void
    {
        this._profile = this._profileService.profile as Profile;
    }

    public async saveProfile(): Promise<boolean>
    {
        try {
            this._profile = await lastValueFrom(this._profileService.mergeProfile(this._profile));
            return true;
        }
        catch (error) {
            console.error(error);
            return false;
        }
    }
}
