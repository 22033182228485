<div class="relative" [style.width.px]="size" [style.height.px]="size">

  <div class="rounded-full" style="background: #F5F5F5">

    @if (percentage < 100) {
      <round-progress
        [current]="percentage"
        [max]="100"
        [color]="'#D12100'"
        [background]="'#FFE0DA'"
        [radius]="size / 2"
        [stroke]="2"
        [rounded]="true"
        [clockwise]="true"
        [responsive]="false"
        [duration]="800"
        [animation]="'easeInOutQuart'"
        [animationDelay]="0"
        >
      </round-progress>
    }

    @switch (profileImageAvailable) {
      @case (false) {
        <mat-icon svgIcon="swan:system-profile" class="text-gray-500"></mat-icon>
      }
      @case (true) {
        <lib-user-icon #userIcon [size]="44" class="m-auto w-11"
        [displayVerified]="true" [profile]="profile"></lib-user-icon>
      }
    }

  </div>
</div>

@if (percentage < 100) {
  <div class="mt-1 text-xs font-bold font-sans text-gray-500" @fadeIn @fadeOut
    transloco="PROFILE.COMPLETED" [translocoParams]="{ percentage: percentage }">
  </div>
}
